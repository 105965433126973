var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
var ApiContext = createContext(null);
var ApiProvider = function (props) {
    var navigate = useNavigate();
    useEffect(function () {
        props.api.connector.listenToApiResponse(navigateTo);
    }, []);
    var navigateTo = function (_a) {
        var href = _a.navigateTo;
        if (!href)
            return;
        var isReload = href.startsWith('$current');
        if (/^http/.test(href)) {
            return (window.location.href = href);
        }
        if (isReload) {
            var _b = /(\?.+?)?(#.*?)?$$/.exec(href) || [], search = _b[1], hash = _b[2];
            href = window.location.pathname + (search || window.location.search) + (hash || window.location.hash);
        }
        navigate(href);
    };
    return _jsx(ApiContext.Provider, __assign({ value: props.api }, { children: props.children }));
};
var useApi = function () {
    return useContext(ApiContext);
};
export { ApiProvider, useApi };
