var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { deepmerge } from '@mui/utils';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import { createContext, useContext, useEffect, useState } from 'react';
import { SocketEvents } from 'jticxm-constants';
import { useApi } from 'shared/api/ApiContext';
import GTMManager from 'shared/utils/GTMManager';
var AppStateContext = createContext(null);
var AppStateProvider = function (props) {
    var parseState = function (data) {
        var user = data.user, settings = data.settings, labels = data.labels, levels = data.levels, radio = data.radio, domain = data.domain, isMobile = data.isMobile, serviceEmail = data.serviceEmail, showUserNameOnStatusBar = data.showUserNameOnStatusBar, availableLanguages = data.availableLanguages, defaultLanguage = data.defaultLanguage, lang = data.lang, version = data.version, locale = data.locale, isHealthWarningStatic = data.isHealthWarningStatic;
        var state = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (user !== undefined && { user: user })), (settings && { settings: settings })), (labels && { labels: labels })), (levels && { levels: levels })), (radio && { radio: radio })), (domain && { domain: domain })), (isMobile && { isMobile: isMobile })), (serviceEmail && { serviceEmail: serviceEmail })), (showUserNameOnStatusBar && { showUserNameOnStatusBar: showUserNameOnStatusBar })), (availableLanguages && { availableLanguages: availableLanguages })), (defaultLanguage && { defaultLanguage: defaultLanguage })), (lang && { lang: lang })), (version && { version: version })), (locale && { locale: locale })), (isHealthWarningStatic && { isHealthWarningStatic: isHealthWarningStatic }));
        if (Object.keys(state).length)
            return state;
        return undefined;
    };
    var _a = useState({
        // this is only needed because of storybook
        labels: (props === null || props === void 0 ? void 0 : props.labels) || {},
        availableLanguages: (props === null || props === void 0 ? void 0 : props.availableLanguages) || [],
        defaultLanguage: props === null || props === void 0 ? void 0 : props.defaultLanguage,
    }), state = _a[0], setState = _a[1];
    var api = useApi();
    var updateState = function (update) {
        setState(function (prevState) { return deepmerge(prevState, update); });
    };
    var handleApiResponse = function (response) {
        var newState = parseState(response);
        if (!newState)
            return;
        setState(function (prevState) {
            // force update frontend when version is outdated
            if (prevState.version && newState.version !== prevState.version) {
                window.location.reload();
            }
            return newState;
        });
        checkLanguage(newState);
        GTMManager.init(newState);
    };
    useEffect(function () {
        api.connector.listenToApiResponse(handleApiResponse);
        api.connector.listenToSocket(updatePoints);
    }, []);
    var updatePoints = function (event) {
        var name = event.name, points = event.data;
        if (name !== SocketEvents.points)
            return;
        updateState({ user: { points: points } });
    };
    var checkLanguage = function (state) {
        // console.log('checkLanguage', state)
        var _a = state || {}, user = _a.user, lang = _a.lang;
        var _b = (user || { lng: lang } || {}).lng, lng = _b === void 0 ? null : _b;
        if (lng) {
            if (document.getElementsByTagName('html')[0].getAttribute('lang') !== lng) {
                // console.log(`got lng from server, setting html lang and moment to ${lng}`)
                document.getElementsByTagName('html')[0].setAttribute('lang', lng);
                moment.locale(lng);
            }
        }
    };
    return (_jsx(AppStateContext.Provider, __assign({ value: __assign({ updateState: updateState }, state) }, { children: props.children })));
};
var useAppState = function () {
    return useContext(AppStateContext);
};
export default AppStateProvider;
export { useAppState };
