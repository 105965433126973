var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
import fieldToMuiProps from './fieldToMuiProps';
export default function RatingWithNumbers(props) {
    var id = props.id, className = props.className, label = props.label, _a = props.length, length = _a === void 0 ? 9 : _a, rest = __rest(props, ["id", "className", "label", "length"]);
    var muiProps = fieldToMuiProps(rest);
    var helperText = muiProps.helperText, error = muiProps.error, muiRest = __rest(muiProps
    // clean up unneeded props
    , ["helperText", "error"]);
    // clean up unneeded props
    delete muiRest.type;
    delete muiRest.required;
    // available range is between 3 and 9
    var optionsLength = length < 3 ? 3 : length > 9 ? 9 : length;
    var numbers = [];
    var n = 1;
    while (n <= optionsLength) {
        numbers.push(n);
        n++;
    }
    return (_jsxs(FormControl, __assign({ error: error, className: className, component: "fieldset" }, { children: [label && _jsx(FormLabel, __assign({ component: "legend" }, { children: label })), _jsx(MuiRadioGroup, __assign({ className: "rating-with-numbers", name: id }, muiRest, { children: numbers.map(function (n, i) { return (_jsx(FormControlLabel, { value: n.toString(), label: n, control: _jsx(Radio, {}) }, i)); }) })), helperText && _jsx(FormHelperText, { children: helperText })] })));
}
