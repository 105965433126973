import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useAppState } from 'shared/core/AppStateProvider';
export default function useVisibleContentHeight(ref, ignoreCaptcha) {
    var isHealthWarningStatic = useAppState().isHealthWarningStatic;
    var updateHeight = useCallback(function () {
        var _a;
        if (ref.current) {
            // documentElement.clientHeight returns wrong data on android but it doesn't have a big impact since the dimmer is now
            // full screen via css. Popup dimensions might be slightly off in some cases
            var height = window.innerHeight;
            // TODO: create constant file for all HTML ids
            var elementId = ignoreCaptcha ? 'healthWarning' : 'sectionBottom';
            var hwHeight = 0;
            if (!isHealthWarningStatic) {
                hwHeight = ((_a = document.getElementById(elementId)) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0;
            }
            var visibleContentHeight = "".concat(height - hwHeight, "px");
            ref.current.style.height = visibleContentHeight;
            var backdrop = ref.current.querySelector('[aria-hidden="true"]');
            if (backdrop)
                backdrop.style.height = visibleContentHeight;
        }
    }, [ref, isHealthWarningStatic]);
    useEffect(function () {
        window.addEventListener('resize', updateHeight);
        return function () {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);
    useLayoutEffect(function () { return updateHeight(); });
}
