var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import { useApi } from 'shared/api/ApiContext';
import { usePopup } from 'shared/core/PopupWrapper';
import FontAwesomeIcon from 'shared/elements/FontAwesomeIcon';
import PointsBadge from 'shared/elements/PointsBadge';
var useStyles = makeStyles({
    buttonBadge: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '1.6rem',
    },
});
export default function Button(props) {
    var _a, _b;
    var buttonRef = props.buttonRef, color = props.color, dataGtm = props.dataGtm, dataGtmEvent = props.dataGtmEvent, icon = props.icon, isPopup = props.isPopup, isSubmitting = props.isSubmitting, label = props.label, variant = props.variant, points = props.points, onClick = props.onClick, _c = props.skipNewTab, skipNewTab = _c === void 0 ? false : _c, rest = __rest(props, ["buttonRef", "color", "dataGtm", "dataGtmEvent", "icon", "isPopup", "isSubmitting", "label", "variant", "points", "onClick", "skipNewTab"]);
    var classes = useStyles();
    var api = useApi();
    var openPopup = usePopup().openPopup;
    var _d = useTheme(), circularProgress = _d.circularProgress, buttonIcon = _d.buttonIcon;
    var _e = circularProgress || {}, sizeContained = _e.sizeContained, sizeDefault = _e.sizeDefault;
    var _f = buttonIcon || {}, containedSolid = _f.containedSolid, outlinedSolid = _f.outlinedSolid;
    var handleClick;
    // update props in case of external links
    if ((_a = rest.to) === null || _a === void 0 ? void 0 : _a.startsWith('https://')) {
        rest.href = rest.to;
        handleClick = function (e) {
            onClick && onClick(e);
            api.trackOutboundLink(rest.href);
        };
        delete rest.to;
    }
    // special link handling in case of popup
    if (isPopup && rest.to) {
        var popupLink_1 = rest.to;
        handleClick = function (e) {
            e.preventDefault();
            onClick && onClick(e);
            openPopup({ url: popupLink_1 });
        };
        delete rest.to;
    }
    // change component in case of internal links
    if (rest.to)
        rest.component = RouterLink;
    // update target based on link
    if (((_b = rest.to) === null || _b === void 0 ? void 0 : _b.startsWith('/@/')) && !skipNewTab)
        rest.target = '_blank';
    // get rid of target when not needed
    if (!rest.to && !rest.href)
        delete rest.target;
    // button icon solid?
    var solid = false;
    if (variant === 'contained')
        solid = containedSolid;
    if (variant === 'outlined')
        solid = outlinedSolid;
    var progressSize = variant === 'contained' ? sizeContained : sizeDefault;
    var labelOrProgress = isSubmitting ? (
    // circular progress inside button
    _jsx(CircularProgress, { size: progressSize, color: 'inherit' })) : (
    // label and icon
    _jsxs(_Fragment, { children: [label, !points && variant !== 'text' && icon && _jsx(FontAwesomeIcon, { name: icon, solid: solid, insideButton: true })] }));
    // stop onClick listener if isSubmitting
    if (isSubmitting) {
        handleClick = function (e) {
            e.preventDefault();
        };
    }
    return (_jsxs(MuiButton, __assign({}, rest, { onClick: handleClick || onClick, ref: buttonRef, "data-gtm": dataGtm || window.location.pathname, "data-gtm-event": dataGtmEvent || 'cta_click', variant: variant, style: color ? { color: color } : null }, { children: [labelOrProgress, Boolean(points) && _jsx(PointsBadge, { className: classes.buttonBadge, points: points })] })));
}
