import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
export default function useLanguageSwitch() {
    var navigate = useNavigate();
    var location = useLocation();
    return function (lang) {
        Cookies.set('lang', lang, { expires: 1 });
        navigate(location);
    };
}
