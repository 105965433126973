import { CXMThemeOverrides } from 'shared/core/CXMTheme'

export default function createOverrides(): CXMThemeOverrides {
  return {
    typography: {
      h1: {
        color: 'currentColor',
      },
    },
  }
}
