var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';
import HelperText from './HelperText';
import fieldToMuiProps from './fieldToMuiProps';
var Switch = function (props) {
    var label = props.label, className = props.className, rest = __rest(props, ["label", "className"]);
    var muiProps = fieldToMuiProps(rest);
    var error = muiProps.error, helperText = muiProps.helperText, muiRest = __rest(muiProps, ["error", "helperText"]);
    return (_jsxs(FormControl, __assign({ error: error, className: className }, { children: [_jsx(FormControlLabel, { label: label, control: _jsx(MuiSwitch, __assign({ checked: props.field.value }, muiRest)) }), _jsx(HelperText, { helperText: helperText })] })));
};
export default Switch;
