var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { duration } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef, useRef } from 'react';
import useVisibleContentHeight from 'shared/hooks/useVisibleContentHeight';
var useStyles = makeStyles(function () { return ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        '@media print': {
            height: 'auto',
        },
        outline: 0,
    },
}); });
var transitionDuration = { enter: duration.enteringScreen, exit: duration.leavingScreen };
var Popup = forwardRef(function (props, ref) {
    var children = props.children, className = props.className, onClose = props.onClose, open = props.open, TransitionProps = props.TransitionProps, other = __rest(props, ["children", "className", "onClose", "open", "TransitionProps"]);
    var classes = useStyles();
    var mouseDownTarget = useRef();
    var handleMouseDown = function (event) {
        mouseDownTarget.current = event.target;
    };
    var handleBackdropClick = function (event) {
        // Ignore the events not coming from the "backdrop"
        // We don't want to close the dialog when clicking the dialog content.
        if (event.target !== event.currentTarget)
            return;
        // Make sure the event starts and ends on the same DOM element.
        if (event.target !== mouseDownTarget.current)
            return;
        mouseDownTarget.current = null;
        if (onClose)
            onClose(event, 'backdropClick');
    };
    var modalRef = useRef(ref);
    useVisibleContentHeight(modalRef, true);
    return (_jsx(Modal, __assign({ className: className, BackdropComponent: Backdrop, BackdropProps: {
            transitionDuration: transitionDuration,
            onClick: handleBackdropClick,
            onMouseDown: handleMouseDown,
        }, closeAfterTransition: true, disableEscapeKeyDown: !onClose, open: open, onClose: function (event, reason) {
            if (reason !== 'backdropClick') {
                onClose(event, reason);
            }
        }, ref: modalRef }, other, { children: _jsx(Fade, __assign({ appear: true, in: open, timeout: transitionDuration }, TransitionProps, { children: _jsx("div", __assign({ className: classes.container }, { children: children })) })) })));
});
export default Popup;
