var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha } from '@mui/material';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import cookies from 'js-cookie';
import { Fragment } from 'react';
import Typography from 'shared/components/Typography';
import { useAppState } from 'shared/core/AppStateProvider';
import useLanguageSwitch from 'shared/hooks/useLanguageSwitch';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components, palette = _a.palette;
    return deepmerge({
        langSwitch: {
            fontSize: '2.2rem',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '1rem',
            paddingBottom: '1.6rem',
            marginBottom: function (_a) {
                var availableLanguages = _a.availableLanguages, isInSignup = _a.isInSignup;
                return (availableLanguages === null || availableLanguages === void 0 ? void 0 : availableLanguages.length) < 2 && !isInSignup ? '4rem' : null;
            },
            color: palette.text.primary,
        },
        langButton: {
            color: 'inherit',
            fontSize: '1.8rem',
            padding: '.5em 0',
            minWidth: '4rem',
            textDecoration: 'none',
            textDecorationColor: 'inherit',
            textDecorationThickness: 0,
            '& > span': {
                textDecoration: 'none',
                textDecorationColor: 'inherit',
                textDecorationThickness: 0,
            },
            fontWeight: 400,
            opacity: 0.7,
            '&:hover': {
                textDecoration: 'none',
                textDecorationColor: 'inherit',
                textDecorationThickness: 0,
                '& > span': {
                    textDecoration: 'none',
                    textDecorationColor: 'inherit',
                    textDecorationThickness: 0,
                },
                backgroundColor: alpha(palette.text.primary, 0.1),
            },
            '&:focus': {
                textDecoration: 'none',
                textDecorationColor: 'inherit',
                textDecorationThickness: 0,
                '& > span': {
                    textDecoration: 'none',
                    textDecorationColor: 'inherit',
                    textDecorationThickness: 0,
                },
                backgroundColor: alpha(palette.text.primary, 0.1),
            },
        },
        currentLang: {
            opacity: 1,
        },
        langTitle: {
            display: 'inline-block',
        },
    }, (_b = components.CxmLangMenu) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
function LangMenu(props) {
    var _a, _b, _c, _d;
    var className = props.className, onSwitchLanguage = props.onSwitchLanguage, noMargin = props.noMargin, isInSignup = props.isInSignup;
    var _e = useAppState(), _f = _e.availableLanguages, availableLanguages = _f === void 0 ? [] : _f, defaultLanguage = _e.defaultLanguage;
    var classes = useStyles(__assign(__assign({}, props), { availableLanguages: availableLanguages, isInSignup: isInSignup }));
    var switchLanguage = useLanguageSwitch();
    var currentLang = (_c = (cookies.get('lang') || ((_b = (_a = navigator === null || navigator === void 0 ? void 0 : navigator.language) === null || _a === void 0 ? void 0 : _a.slice) === null || _b === void 0 ? void 0 : _b.call(_a, 0, 2)))) === null || _c === void 0 ? void 0 : _c.toLowerCase();
    currentLang = (_d = availableLanguages.filter(function (language) { return language === currentLang; })) === null || _d === void 0 ? void 0 : _d[0];
    if (!currentLang)
        currentLang = defaultLanguage === null || defaultLanguage === void 0 ? void 0 : defaultLanguage.toLowerCase();
    if (!availableLanguages || availableLanguages.length < 2)
        return _jsx("div", { className: [className, classes.langSwitch].join(' ') });
    return (_jsx(Typography, __assign({ id: 'LangMenu', component: "nav", className: [className, classes.langSwitch].join(' '), noMargin: noMargin }, { children: availableLanguages.map(function (lang, i) {
            var _a;
            return (_jsxs(Fragment, { children: [_jsx(Button, __assign({ variant: "text", className: classNames(classes.langButton, (_a = {},
                            _a[classes.currentLang] = currentLang === lang,
                            _a)), onClick: function () {
                            switchLanguage(lang);
                            onSwitchLanguage && onSwitchLanguage(lang);
                        } }, { children: _jsx("span", __assign({ className: classes.langTitle }, { children: lang.toUpperCase() })) })), i !== availableLanguages.length - 1 ? '|' : null] }, lang));
        }) })));
}
export default LangMenu;
