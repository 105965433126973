var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from 'shared/components/Link';
import Typography from 'shared/components/Typography';
import compileLabel from 'shared/utils/compileLabel';
export default function ReactProsemirrorParser(props) {
    var document = props.document, variant = props.variant, replacements = props.replacements;
    if (!document || document.type !== 'doc')
        return null;
    return _jsx(_Fragment, { children: document.content.map(function (node, index) { return renderNode(node, index, variant, replacements); }) });
}
function renderNode(node, index, variant, replacements) {
    // increment index
    index += 1;
    // compile placeholders
    if (replacements)
        node.text = compileLabel(node.text, replacements);
    if (node.content) {
        var nodeProps = Object.assign({}, node.attrs, { variant: variant });
        var compFunction = typeToComponent[node.type];
        var _a = typeof compFunction === 'function' ? typeToComponent[node.type](nodeProps) : [], Component = _a[0], componentProps = _a[1];
        if (!Component) {
            console.error("Could not render ".concat(node.type));
            return null;
        }
        var childrenVariant_1 = componentProps.childrenVariant, textAlign = componentProps.textAlign, rest = __rest(componentProps, ["childrenVariant", "textAlign"]);
        return (_jsx(Component, __assign({ style: textAlign ? { textAlign: textAlign } : null }, rest, { children: node.content.map(function (nestedNode, ni) {
                return renderNode(nestedNode, index * 10 + ni, childrenVariant_1, replacements);
            }) }), index));
    }
    else if (node.marks) {
        return node.marks.reverse().reduce(function (prev, curr, i) {
            var markIndex = index * 10 + i;
            if (!prev)
                return renderMark(__assign(__assign({}, curr), { index: markIndex }), node.text);
            else
                return renderMark(__assign(__assign({}, curr), { index: markIndex }), prev);
        }, null);
    }
    else if (node.text) {
        return node.text;
    }
    else if (node.type === 'hardBreak') {
        return _jsx("br", {}, index);
    }
    return null;
}
function renderMark(mark, text) {
    var type = mark.type, index = mark.index, _a = mark.attrs, attrs = _a === void 0 ? {} : _a;
    attrs.className = attrs.class;
    delete attrs.class;
    switch (type) {
        case 'bold':
            return (_jsx("strong", __assign({}, attrs, { children: text }), index));
        case 'italic':
            return (_jsx("em", __assign({}, attrs, { children: text }), index));
        case 'link':
            var href = attrs.href, rest = __rest(attrs, ["href"]);
            return (_jsx(Link, __assign({ to: href }, rest, { children: text }), index));
        case 'small':
            return (_jsx("small", __assign({}, attrs, { children: text }), index));
        case 'superscript':
            return (_jsx("sup", __assign({}, attrs, { children: text }), index));
    }
}
var typeToComponent = {
    heading: function (_a) {
        var level = _a.level, textAlign = _a.textAlign;
        return [Typography, { variant: "h".concat(level), textAlign: textAlign }];
    },
    bulletList: function (_a) {
        var variant = _a.variant;
        return [Typography, { component: 'ul', variant: variant }];
    },
    listItem: function () { return [Typography, { component: 'li' }]; },
    orderedList: function (_a) {
        var variant = _a.variant;
        return [Typography, { component: 'ol', variant: variant }];
    },
    paragraph: function (_a) {
        var variant = _a.variant, textAlign = _a.textAlign;
        return [Typography, { component: 'p', variant: variant, textAlign: textAlign }];
    },
    table: function () { return [
        function (_a) {
            var children = _a.children;
            return (_jsx(Table, { children: _jsx(TableBody, { children: children }) }));
        },
        {},
    ]; },
    tableCell: function () { return [TableCell, { component: 'td', childrenVariant: 'body2' }]; },
    tableHeader: function () { return [TableCell, { component: 'th', variant: 'head', childrenVariant: 'subtitle2' }]; },
    tableRow: function () { return [TableRow, { component: 'tr' }]; },
};
