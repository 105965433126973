var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import ErrorIcon from 'shared/components/forms/SimpleForm/ErrorIcon';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components;
    return deepmerge({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        iconWrapper: {
            marginRight: 5,
        },
        icon: {},
    }, (_b = components.CxmErrorHelperText) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
export default function ErrorHelperText(props) {
    var _a;
    var error = props.error;
    var classes = useStyles();
    var root = classes.root, restClasses = __rest(classes, ["root"]);
    var theme = useTheme();
    if (typeof error !== 'string')
        return null;
    return (_jsxs("span", __assign({ className: root }, { children: [_jsx(ErrorIcon, { classes: restClasses, iconStyle: (_a = theme.components.CxmErrorHelperText) === null || _a === void 0 ? void 0 : _a.iconStyle }), " ", error] })));
}
