var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import validateField from 'shared/components/forms/SimpleForm/validateField';
import { useAppState } from 'shared/core/AppStateProvider';
import FormField from './FormField';
var useStyles = makeStyles(function () { return ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1.6rem',
        flexShrink: 0,
        maxWidth: '40rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        '& > *:not(:first-child)': {
            marginTop: '1.6rem',
        },
    },
}); });
var getInitialValues = function (fields, values) {
    return fields.reduce(function (acc, cur) {
        var id = cur.id, type = cur.type, defaultValue = cur.defaultValue;
        if (!id)
            return acc;
        if (values && id in values)
            acc[id] = values[id];
        else if (type === 'checkbox') {
            acc[id] = defaultValue || false;
        }
        else if (defaultValue) {
            acc[id] = defaultValue;
        }
        else
            acc[id] = '';
        return acc;
    }, {});
};
var validateFields = function (fields, values, settings) {
    var errors = {};
    for (var _i = 0, fields_1 = fields; _i < fields_1.length; _i++) {
        var field = fields_1[_i];
        var id = field.id, helperText = field.helperText;
        var value = values[id];
        if (!validateField(field, value, fields, values, settings))
            errors[id] = helperText;
    }
    return errors;
};
var SimpleForm = function (props) {
    var fieldsProp = props.fields, valuesProp = props.values, onCancel = props.onCancel, onSubmitProp = props.onSubmit, classesProp = props.classes, className = props.className, isSubmitDisabled = props.isSubmitDisabled, rest = __rest(props, ["fields", "values", "onCancel", "onSubmit", "classes", "className", "isSubmitDisabled"]);
    var fields = fieldsProp.filter(Boolean);
    var classes = useStyles({ classes: { root: classesProp === null || classesProp === void 0 ? void 0 : classesProp.root } });
    var onSubmit = function (values, methods) { return __awaiter(void 0, void 0, void 0, function () {
        var setErrors, setFieldValue, error_1, inputError, errors, _i, fields_2, field;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setErrors = methods.setErrors, setFieldValue = methods.setFieldValue;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onSubmitProp(values, methods)];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    inputError = (((_b = (_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || {}).inputError;
                    if (inputError) {
                        errors = inputError.reduce(function (acc, _a) {
                            var cur = _a[0];
                            var field = fields.find(function (_a) {
                                var id = _a.id;
                                return id === cur;
                            });
                            if (field)
                                acc[cur] = field.helperText;
                            return acc;
                        }, {});
                        setErrors(errors);
                    }
                    for (_i = 0, fields_2 = fields; _i < fields_2.length; _i++) {
                        field = fields_2[_i];
                        if (field.valueOnError !== undefined) {
                            setFieldValue(field.id, field.valueOnError);
                        }
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var settings = useAppState().settings;
    // sanitize fields ids
    fields.forEach(function (field) {
        field.id = field.id && String(field.id).replace(/[.]/g, '');
    });
    return (_jsx(Formik, __assign({ initialValues: getInitialValues(fields, valuesProp), validate: function (values) { return validateFields(fields, values, settings); }, onSubmit: onSubmit }, { children: function (_a) {
            var isSubmitting = _a.isSubmitting;
            return (_jsx(Form, __assign({ className: classNames(classes.root, className), noValidate: true }, rest, { children: fields.map(function (field, index) {
                    var id = field.id, className = field.className, type = field.type;
                    if (type !== 'radio')
                        delete field.defaultValue; // cannot have value and default value
                    var _className = classNames(className, classesProp && classesProp[id], classesProp && classesProp["type_".concat(type)]);
                    return (_jsx(FormField, __assign({}, field, { className: _className, onCancel: onCancel, isSubmitting: isSubmitting, isSubmitDisabled: isSubmitDisabled }), index));
                }) })));
        } })));
};
export default SimpleForm;
