var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
export { ThemeProvider } from '@mui/material/styles';
var CXMTheme = /** @class */ (function () {
    function CXMTheme() {
    }
    CXMTheme.prototype.set = function (brandTheme) {
        this.theme = brandTheme;
    };
    CXMTheme.prototype.get = function (themeName) {
        if (!this.theme)
            throw new Error('Brand theme is not set, make sure to call CXMTheme.set on startup');
        return this.theme.get(themeName);
    };
    CXMTheme.prototype.extractGlobalTypography = function (themeName) {
        var theme = this.theme.get(themeName);
        var stylesNoColor = function (style) {
            var filteredStyle = Object.assign({}, style);
            delete filteredStyle.color;
            return filteredStyle;
        };
        var typoStyleList = {};
        var tagMapping = { h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6', body1: 'p', body2: 'small' };
        for (var muiTag in tagMapping) {
            var tag = tagMapping[muiTag];
            typoStyleList["".concat(tag)] = __assign({}, stylesNoColor(theme.typography[muiTag]));
        }
        return typoStyleList;
    };
    return CXMTheme;
}());
export default new CXMTheme();
export function createCxmTheme(options) {
    var defaults = options.defaults, createComponents = options.createComponents, createTypography = options.createTypography, palette = options.palette, overrides = options.overrides;
    var theme = createTheme(defaults);
    if (palette)
        theme.palette = deepmerge(theme.palette, palette, { clone: true });
    var typography = createTypography(theme.breakpoints, theme.palette);
    theme.typography = deepmerge(theme.typography, typography, { clone: true });
    theme.components = createComponents(theme.breakpoints, theme.palette, theme.typography);
    if (!overrides)
        return theme;
    return createTheme(deepmerge(theme, overrides, { clone: true }));
}
