import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAppState } from 'shared/core/AppStateProvider';
var useLayoutDimensions = function () {
    var _a = useState(0), sectionBottomHeight = _a[0], setSectionBottomHeight = _a[1];
    var _b = useState(0), topBannerHeight = _b[0], setTopBannerHeight = _b[1];
    var isHealthWarningStatic = useAppState().isHealthWarningStatic;
    var lastKnown = useRef({ sectionBottomHeight: 0, topBannerHeight: 0 });
    var calcDimensions = useCallback(function () {
        var _a, _b;
        // let newDimensions = {}
        var newSectionBottomHeight = 0;
        if (!isHealthWarningStatic) {
            newSectionBottomHeight = Math.floor(((_a = document.getElementById('sectionBottom')) === null || _a === void 0 ? void 0 : _a.clientHeight) - 1) || 0;
        }
        var newTopBannerHeight = Math.floor(((_b = document.getElementById('TopBanner')) === null || _b === void 0 ? void 0 : _b.clientHeight) - 1) || 0;
        if (newSectionBottomHeight !== lastKnown.current.sectionBottomHeight) {
            setSectionBottomHeight(newSectionBottomHeight);
            lastKnown.current.sectionBottomHeight = newSectionBottomHeight;
        }
        if (newTopBannerHeight !== lastKnown.current.topBannerHeight) {
            setTopBannerHeight(newTopBannerHeight);
            lastKnown.current.topBannerHeight = newTopBannerHeight;
        }
    }, [isHealthWarningStatic]);
    useLayoutEffect(function () {
        calcDimensions();
    });
    useEffect(function () {
        window.addEventListener('resize', calcDimensions);
        return function () {
            window.removeEventListener('resize', calcDimensions);
        };
    }, [calcDimensions]);
    return { sectionBottomHeight: sectionBottomHeight, topBannerHeight: topBannerHeight };
};
export default useLayoutDimensions;
