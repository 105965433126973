import { Palette, alpha } from '@mui/material/styles'

import colors from '../colors'

export default {
  primary: {
    main: colors.darkBlue,
    // main: Colors.lightBlue,
    light: colors.lightBlue,
    // dark: Colors.lightBlue,
    dark: colors.darkBlue,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.white,
    light: colors.white,
    dark: colors.white,
    contrastText: colors.lightBlue,
  },
  text: {
    primary: colors.darkBlue,
    secondary: colors.white,
    disabled: alpha(colors.darkBlue, 0.7),
  },
  background: {
    default: colors.yellow,
    paper: colors.yellow,
  },
  info: {
    main: colors.darkBlue,
    light: colors.yellow,
  },
  error: {
    main: colors.pink,
  },
  articlePreviewCard: {
    text: colors.darkBlue,
  },
  logo: {
    main: colors.darkBlue,
  },
  storyAdsBadge: {
    main: colors.storyAdsBadge,
  },
} as Palette
