var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from 'shared/components/Typography';
import Popup from 'shared/components/popup/Popup';
import PopupBox from 'shared/components/popup/PopupBox';
import PopupContent from 'shared/components/popup/PopupContent';
import PopupHeader from 'shared/components/popup/PopupHeader';
import Media from 'shared/elements/Media';
export default function PopupStatic(props) {
    var children = props.children, classes = props.classes, hideClose = props.hideClose, id = props.id, media = props.media, _a = props.mediaProps, mediaProps = _a === void 0 ? {} : _a, open = props.open, onClose = props.onClose, title = props.title;
    return (_jsx(Popup, __assign({ id: id, open: open, onClose: onClose, className: classes === null || classes === void 0 ? void 0 : classes.root, keepMounted: true }, { children: _jsxs(PopupBox, __assign({ className: classes === null || classes === void 0 ? void 0 : classes.box }, { children: [_jsx(PopupHeader, __assign({ onClose: onClose, hideClose: hideClose, className: classes === null || classes === void 0 ? void 0 : classes.header }, { children: media && (_jsx(Media, __assign({ className: classes === null || classes === void 0 ? void 0 : classes.media, src: media, size: "original", controls: false, autoplay: true, loop: true }, mediaProps))) })), _jsxs(PopupContent, __assign({ className: classes === null || classes === void 0 ? void 0 : classes.content, hasCloseButton: !hideClose, hasHeaderMedia: !!media }, { children: [_jsx(Typography, __assign({ variant: "h4", className: classes === null || classes === void 0 ? void 0 : classes.title }, { children: title })), children] }))] })) })));
}
