var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export default function Audio(props) {
    var id = props.id, className = props.className, mediaRef = props.mediaRef, audio = props.audio, autoplay = props.autoplay, controls = props.controls, controlsList = props.controlsList, loop = props.loop, onClick = props.onClick, style = props.style, handleLoadedData = props.handleLoadedData, handleTimeUpdate = props.handleTimeUpdate, handleLoadedMetadata = props.handleLoadedMetadata;
    var path = audio.path, type = audio.type;
    return (_jsx("audio", __assign({ ref: mediaRef, id: id, className: className, autoPlay: autoplay, controls: controls, controlsList: controlsList, loop: loop, onClick: onClick, onTimeUpdate: handleTimeUpdate, onLoadedMetadata: handleLoadedMetadata, onLoadedData: handleLoadedData, style: style }, { children: _jsx("source", { src: path, type: type }) }), path));
}
