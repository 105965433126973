var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@mui/material';
import Popper from '@mui/material/Popper';
import makeStyles from '@mui/styles/makeStyles';
import { createContext, useContext, useState } from 'react';
import IconButton from 'shared/components/IconButton';
import Typography from 'shared/components/Typography';
import CXMTheme, { ThemeProvider } from 'shared/core/CXMTheme';
import RichText from 'shared/elements/RichText';
var InfoBoxContext = createContext(null);
export var useInfoBox = function () { return useContext(InfoBoxContext); };
export var InfoBoxProvider = function (props) {
    var _a = useState(''), openInfoBox = _a[0], setOpenInfoBox = _a[1];
    var children = props.children;
    return _jsx(InfoBoxContext.Provider, __assign({ value: { openInfoBox: openInfoBox, setOpenInfoBox: setOpenInfoBox } }, { children: children }));
};
var useStyles = makeStyles(function () { return ({
    infoBox: {
        maxWidth: '44rem',
        width: 'calc(100vw - 3.2rem)',
    },
    infoBoxPaper: {
        position: 'relative',
        top: '-1.6rem',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
    },
    infoBoxHeader: {
        display: 'flex',
    },
    infoBoxTitle: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: '1.6rem',
    },
    infoBoxContent: {
        padding: '.8rem 1.6rem 1.6rem',
    },
}); });
function InfoBox(props) {
    var id = props.id, _a = props.anchorEl, anchorEl = _a === void 0 ? null : _a, _b = props.placement, placement = _b === void 0 ? 'top' : _b, title = props.title, content = props.content, onClose = props.onClose;
    var classes = useStyles(props);
    var _c = useInfoBox(), openInfoBox = _c.openInfoBox, setOpenInfoBox = _c.setOpenInfoBox;
    var handleClose = function () {
        setOpenInfoBox('');
        onClose && onClose();
    };
    return (_jsx(Popper, __assign({ id: id, open: id === openInfoBox, anchorEl: anchorEl, placement: placement, className: classes.infoBox, popperOptions: {
            modifiers: [
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        padding: 16,
                    },
                },
            ],
        }, 
        // the following props are here just to make TS happy
        placeholder: "bottom", onPointerEnterCapture: null, onPointerLeaveCapture: null }, { children: _jsxs(Paper, __assign({ elevation: 0, square: true, className: classes.infoBoxPaper }, { children: [_jsxs("header", __assign({ className: classes.infoBoxHeader }, { children: [_jsx(Typography, __assign({ variant: "h5", className: classes.infoBoxTitle, noMargin: true }, { children: _jsx("span", __assign({ title: title }, { children: title })) })), _jsx(IconButton, { icon: "times", "aria-label": "close", primary: true, onClick: handleClose, size: "large" })] })), _jsx(Typography, __assign({ component: "div", className: classes.infoBoxContent, noMargin: true }, { children: _jsx(RichText, { content: content }) }))] })) })));
}
export default function ThemedInfoBox(props) {
    var theme = CXMTheme.get(props.theme);
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(InfoBox, __assign({}, props)) })));
}
