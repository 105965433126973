var widthBySize = {
    xxs: 100,
    xs: 200,
    sm: 400,
    md: 690,
    lg: 800,
    xl: 1024,
    xxl: 1440,
};
export default function sizeImagePath(path, size) {
    if (!path || size === 'original')
        return path;
    var url = path;
    var pixelRatio = window.devicePixelRatio && window.devicePixelRatio > 1 ? 2 : 1.5;
    var numericSize = typeof size === 'number' ? size : widthBySize[size];
    var width = numericSize && Math.floor(numericSize * pixelRatio);
    if (width)
        url += '?w=' + width + '&crop';
    return url;
}
