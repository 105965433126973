import { Palette, alpha } from '@mui/material'
import colors from 'camel-de/src/theme/colors'

export default {
  primary: {
    main: colors.yellow,
    light: colors.yellow,
    dark: colors.yellow,
    contrastText: colors.darkBlue,
  },
  secondary: {
    main: colors.darkBlue,
    light: colors.darkBlue,
    dark: colors.darkBlue,
    contrastText: colors.yellow,
  },
  text: {
    primary: colors.white,
    secondary: colors.darkBlue,
    disabled: alpha(colors.white, 0.7),
  },
  background: {
    default: colors.lightBlue,
    paper: colors.lightBlue,
  },
  info: {
    main: colors.lightBlue,
    light: colors.white,
  },
  error: {
    main: colors.red,
    light: colors.white,
    contrastText: colors.red,
  },
  success: {
    main: colors.green,
    light: colors.white,
  },
  warning: {
    main: colors.orange,
    light: colors.white,
  },
  // content hub
  articlePreviewCard: {
    text: colors.white,
    background: colors.lightBlue,
  },
  articleCategory: {
    background: colors.white,
  },
  logo: {
    main: colors.yellow,
  },
  rewardShopCard: {
    background: alpha(colors.darkBlue, 0.07),
  },
  storyAdsBadge: {
    main: colors.storyAdsBadge,
  },
} as Palette
