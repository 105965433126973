"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SocketEvents = {
    connect: 'connect',
    join: 'join',
    points: 'points',
    render: 'render',
    festivalVisit: 'festival-visit',
};
exports.default = SocketEvents;
