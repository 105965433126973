var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
var useStyles = makeStyles(function () { return ({
    root: {
        marginRight: '.3em',
    },
}); });
export default function CurrencyIcon(props) {
    var _a = useTheme().currencyIcon, currencyIcon = _a === void 0 ? 'fa-coins' : _a;
    var classes = useStyles(props);
    if (typeof currencyIcon === 'string') {
        return _jsx(Icon, { className: classNames(currencyIcon, classes.root) });
    }
    return _jsx(AssetIcon, {});
}
var useAssetIconStyles = makeStyles(function (_a) {
    var currencyIcon = _a.currencyIcon;
    return ({
        root: __assign({ display: 'inline-block', width: (typeof currencyIcon !== 'string' && (currencyIcon === null || currencyIcon === void 0 ? void 0 : currencyIcon.width)) || '.9em', backgroundColor: 'currentColor', maskSize: 'contain', lineHeight: 'inherit', marginRight: '.3em' }, (typeof currencyIcon !== 'string' && {
            height: currencyIcon.height,
            mask: "url(\"".concat(currencyIcon.url, "\") no-repeat left"),
            verticalAlign: currencyIcon.verticalAlign,
        })),
    });
});
function AssetIcon() {
    var classes = useAssetIconStyles();
    return _jsx("span", { className: classes.root, title: "Currency" });
}
