import { useEffect, useState } from 'react';
export default function useScript(url, cleanup) {
    if (cleanup === void 0) { cleanup = true; }
    var _a = useState(false), loaded = _a[0], setLoaded = _a[1];
    useEffect(function () {
        if (!url)
            return;
        var script = document.createElement('script');
        script.id = 'script_' + Date.now();
        script.src = url;
        script.async = true;
        function isLoaded(e) {
            // console.log('src', e.target.getAttribute('src'))
            if (e.target.getAttribute('src') === url)
                setLoaded(true);
        }
        document.body.appendChild(script);
        document.getElementById(script.id).addEventListener('load', isLoaded, false);
        // console.log('script added', url)
        return cleanup
            ? function () {
                document.getElementById(script.id).removeEventListener('load', isLoaded);
                document.body.removeChild(script);
                // console.log('script removed', url)
            }
            : null;
    }, [url, cleanup]);
    if (!url)
        return true;
    return loaded;
}
