var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress } from '@mui/material';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useApi } from 'shared/api/ApiContext';
import Popup from 'shared/components/popup/Popup';
import PopupBox from 'shared/components/popup/PopupBox';
import PopupContent from 'shared/components/popup/PopupContent';
import PopupHeader from 'shared/components/popup/PopupHeader';
import GTMManager from 'shared/utils/GTMManager';
export var POPUP_PARAM = 'popup';
var defaultContext = {
    openPopup: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            console.log('PopupWrapper not initialised yet');
            return [2 /*return*/];
        });
    }); },
};
var PopupContext = createContext(defaultContext);
export var usePopup = function () { return useContext(PopupContext); };
export default function PopupWrapper(props) {
    var _this = this;
    var children = props.children, templateFactory = props.templateFactory;
    var _a = useState(null), currentPopup = _a[0], setCurrentPopup = _a[1];
    var _b = useState(null), currentContentData = _b[0], setCurrentContentData = _b[1];
    var api = useApi();
    var pendingApiCall = useRef(null);
    var _c = useSearchParams(), search = _c[0], setSearchParams = _c[1];
    var closePopup = function () {
        var _a, _b, _c;
        // cancelling pending api call
        (_b = (_a = pendingApiCall === null || pendingApiCall === void 0 ? void 0 : pendingApiCall.current) === null || _a === void 0 ? void 0 : _a.cancel) === null || _b === void 0 ? void 0 : _b.call(_a);
        (_c = currentPopup === null || currentPopup === void 0 ? void 0 : currentPopup.afterClose) === null || _c === void 0 ? void 0 : _c.call(currentPopup);
        setCurrentPopup(null);
        /**
         * Clear url search params - here we are not using
         * useSearchParams because we don't want react router
         * to track this in order to avoid unwanted api requests
         */
        if (currentPopup && !currentPopup.isLoading) {
            search.delete(POPUP_PARAM);
            setSearchParams(search);
        }
    };
    var onTransitionExited = function () {
        setCurrentContentData(null);
    };
    var openPopup = function (popup) { return __awaiter(_this, void 0, void 0, function () {
        var url, apiCall, request, data, navigateTo, error_1, pageUrl;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    url = popup.url;
                    if (currentPopup && currentPopup.url === url)
                        return [2 /*return*/];
                    setCurrentPopup(__assign(__assign({}, popup), { isLoading: true }));
                    // cancelling pending api call
                    (_b = (_a = pendingApiCall === null || pendingApiCall === void 0 ? void 0 : pendingApiCall.current) === null || _a === void 0 ? void 0 : _a.cancel) === null || _b === void 0 ? void 0 : _b.call(_a);
                    apiCall = api.getRoute({ pathname: url });
                    pendingApiCall.current = apiCall;
                    request = apiCall.request;
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, request];
                case 2:
                    data = _e.sent();
                    GTMManager.openPopUp(__assign(__assign({}, data), popup));
                    navigateTo = data.navigateTo;
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _e.sent();
                    navigateTo = (_d = (_c = error_1.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.navigateTo;
                    return [3 /*break*/, 4];
                case 4:
                    if (navigateTo) {
                        setCurrentPopup(null);
                    }
                    else {
                        setCurrentPopup(__assign(__assign({}, popup), { isLoading: false }));
                        setCurrentContentData(data);
                        pageUrl = new URL(window.location.href);
                        pageUrl.searchParams.set(POPUP_PARAM, url);
                        window.history.pushState({}, null, decodeURIComponent(pageUrl.toString()));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var _d = currentPopup || {}, _e = _d.isClosable, isClosable = _e === void 0 ? true : _e, _f = _d.isLoading, isLoading = _f === void 0 ? false : _f;
    var _g = currentContentData || {}, component = _g.component, page = _g.page;
    var _h = (page || {}).preventClickOutside, preventClickOutside = _h === void 0 ? false : _h;
    useEffect(function () {
        api.connector.listenToApiResponse(function (_a) {
            var popup = _a.openPopup;
            if (popup)
                openPopup(popup).then( /*noop*/);
        });
    }, []);
    var content;
    if (isLoading || !component)
        content = (_jsx("div", __assign({ style: { textAlign: 'center' } }, { children: _jsx(CircularProgress, {}) })));
    else {
        var isPopup = component.includes(POPUP_PARAM);
        content = templateFactory(currentContentData);
        if (!isPopup) {
            content = (_jsxs(PopupBox, { children: [_jsx(PopupHeader, {}), _jsx(PopupContent, { children: content })] }));
        }
    }
    // closing closable popups on location change
    var location = useLocation();
    var refClosable = useRef(isClosable);
    refClosable.current = isClosable;
    useEffect(function () {
        if (refClosable.current) {
            closePopup();
        }
    }, [location]);
    var open = !!currentPopup;
    return (_jsxs(PopupContext.Provider, __assign({ value: { openPopup: openPopup, onClose: isClosable && closePopup } }, { children: [_jsx(Popup, __assign({ TransitionProps: {
                    onExited: onTransitionExited,
                }, open: open, onClose: isClosable && !preventClickOutside ? closePopup : null }, { children: content })), children] })));
}
