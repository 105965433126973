var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default function getPasswordStrength(password, patterns) {
    var passwordPatterns = patterns || {
        required: ['.{8,}'],
        optional: {
            min: 3,
            patterns: ['(?=.[^\\w])', '(?=.*[A-Z])', '(?=.*[a-z])', '(?=.*[0-9])'],
        },
    };
    var required = passwordPatterns.required, _a = passwordPatterns.optional, _b = _a === void 0 ? {} : _a, _c = _b.min, minOptional = _c === void 0 ? 0 : _c, _d = _b.patterns, optional = _d === void 0 ? [] : _d;
    var countMatchedPatterns = function (total, pattern) { return total + (new RegExp(pattern).test(password) ? 1 : 0); };
    var numPatternsRequired = required.length + minOptional;
    var numPatternsTotal = required.length + optional.length;
    var numMatchedRequired = required.reduce(countMatchedPatterns, 0);
    var numMatchedOptional = optional.reduce(countMatchedPatterns, 0);
    var numMatchedTotal = numMatchedRequired + numMatchedOptional;
    var score = numMatchedTotal / numPatternsTotal;
    var passed = true;
    if (numMatchedRequired < required.length || numMatchedTotal < numPatternsRequired) {
        passed = false;
        score = Math.min(score, 0.5);
    }
    var idx = Math.round(descriptions.length * score) - 1;
    idx = Math.max(0, Math.min(idx, descriptions.length - 1));
    return __assign({ score: score, passed: passed }, descriptions[idx]);
}
var descriptions = [
    { id: 'worst', color: 'red', caption: 'PASSWORD_STRENGTH_BAD' },
    { id: 'bad', color: 'red', caption: 'PASSWORD_STRENGTH_BAD' },
    { id: 'weak', color: 'coral', caption: 'PASSWORD_STRENGTH_WEAK' },
    { id: 'good', color: 'gold', caption: 'PASSWORD_STRENGTH_OKAY' },
    { id: 'best', color: 'limegreen', caption: 'PASSWORD_STRENGTH_GOOD' },
];
