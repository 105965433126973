import { Breakpoints, Palette } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'

const systemFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
]

const typographies = {
  Camel: ['Camel'].concat(systemFonts).join(','),
  Graphik: ['Graphik'].concat(systemFonts).join(','),
}

export default typographies

export const createTypography = (breakpoints: Breakpoints, palette: Palette): TypographyOptions => ({
  h1: {
    fontSize: '3.6rem',
    fontFamily: typographies.Camel,
    fontVariantLigatures: 'no-common-ligatures',
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 1.2,
    hyphens: 'manual',
    textAlign: 'center',
    color: palette.primary.main,
    [breakpoints.up('sm')]: {
      fontSize: '4.2rem',
    },
  },
  h2: {
    fontSize: '3.6rem',
    fontFamily: typographies.Graphik,
    fontWeight: 900,
    letterSpacing: 0,
    lineHeight: 1.2,
    hyphens: 'manual',
    [breakpoints.up('sm')]: {
      fontSize: '4.2rem',
    },
  },
  h3: {
    fontSize: '3rem',
    fontFamily: typographies.Camel,
    fontVariantLigatures: 'no-common-ligatures',
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 1.1,
    hyphens: 'manual',
    [breakpoints.up('sm')]: {
      fontSize: '3.8rem',
    },
  },
  h4: {
    fontFamily: typographies.Graphik,
    fontWeight: 900,
    fontSize: '2.8rem',
    hyphens: 'manual',
    lineHeight: 1.2,
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      fontSize: '3.2rem',
    },
  },
  h5: {
    fontFamily: typographies.Camel,
    fontWeight: 700,
    fontSize: '2.2rem',
    lineHeight: 1.2,
    fontVariantLigatures: 'no-common-ligatures',
    hyphens: 'manual',
  },
  h6: {
    fontFamily: typographies.Graphik,
    fontWeight: 600,
    fontSize: '2rem',
    hyphens: 'manual',
    lineHeight: 1.2,
    [breakpoints.up('sm')]: {
      fontSize: '2.2rem',
    },
  },
  body1: {
    fontFamily: typographies.Graphik,
    fontSize: '1.8rem',
    lineHeight: '1.35',
    hyphens: 'auto',
  },
  body2: {
    fontFamily: typographies.Graphik,
    fontSize: '1.6rem',
    lineHeight: '1.3',
    hyphens: 'auto',
  },
  subtitle1: {
    fontFamily: typographies.Graphik,
    fontSize: '1.8rem',
    fontWeight: 600,
    lineHeight: 1.3,
    letterSpacing: 0,
    hyphens: 'auto',
  },
  subtitle2: {
    fontFamily: typographies.Graphik,
    fontSize: '1.6rem',
    lineHeight: 1.3,
    letterSpacing: 0,
    fontWeight: 600,
    hyphens: 'auto',
  },
  overline: {
    display: 'block',
    fontFamily: typographies.Graphik,
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: 1.167,
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    hyphens: 'auto',
  },
  caption: {
    display: 'block',
    fontFamily: typographies.Graphik,
    fontSize: 10,
    lineHeight: 1.2,
    hyphens: 'auto',
  },
})
