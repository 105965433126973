var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { Field } from 'formik';
import Button from 'shared/components/Button';
import Typography from 'shared/components/Typography';
import AutocompletedLocality from 'shared/components/forms/SimpleForm/AutocompletedLocality';
import Checkbox from 'shared/components/forms/SimpleForm/Checkbox';
import CigaretteSelection from 'shared/components/forms/SimpleForm/CigaretteSelection';
import DateField from 'shared/components/forms/SimpleForm/DateField';
import PasswordField from 'shared/components/forms/SimpleForm/PasswordField';
import RadioGroup from 'shared/components/forms/SimpleForm/RadioGroup';
import RatingWithNumbers from 'shared/components/forms/SimpleForm/RatingWithNumbers';
import Select from 'shared/components/forms/SimpleForm/Select';
import Switch from 'shared/components/forms/SimpleForm/Switch';
import TextField from 'shared/components/forms/SimpleForm/TextField';
import isFieldConditionTrue from 'shared/utils/isFieldConditionTrue';
import PostalCodeAutofillCityDE from './PostalCodeAutofillCityDE';
var useStyles = makeStyles(function () { return ({
    invisible: {
        opacity: 0,
        height: 0,
        margin: '0 !important',
        overflow: 'hidden',
        position: 'absolute',
        pointerEvents: 'none',
    },
}); });
export default function FormField(props) {
    var _a;
    var classNameProp = props.className, type = props.type, id = props.id, helperText = props.helperText, helperAlwaysVisible = props.helperAlwaysVisible, invisible = props.invisible, visibleIf = props.visibleIf, requiredIf = props.requiredIf, onCancel = props.onCancel, linkTarget = props.linkTarget, // only for type "button"
    buttonIcon = props.buttonIcon, // only for type "button"
    buttonPoints = props.buttonPoints, // only for type "button"
    isProcessing = props.isProcessing, // only for type "button"
    isSubmitting = props.isSubmitting, // only for type "button"
    isSubmitDisabled = props.isSubmitDisabled, // only for type "button"
    componentOptions = props.componentOptions, labelVariant = props.labelVariant, noMargin = props.noMargin, rest = __rest(props, ["className", "type", "id", "helperText", "helperAlwaysVisible", "invisible", "visibleIf", "requiredIf", "onCancel", "linkTarget", "buttonIcon", "buttonPoints", "isProcessing", "isSubmitting", "isSubmitDisabled", "componentOptions", "labelVariant", "noMargin"]);
    var label = props.label;
    // avoid this prop to be rendered as HTML attribute
    delete rest.valueOnError;
    if (requiredIf) {
        var isRequired = isFieldConditionTrue(requiredIf);
        if (isRequired)
            rest.required = true;
    }
    var conditionallyInvisible = false;
    if (visibleIf) {
        var isVisible = isFieldConditionTrue(visibleIf);
        conditionallyInvisible = !isVisible;
    }
    var classes = useStyles();
    var className = classNames(classNameProp, (_a = {}, _a[classes.invisible] = invisible || conditionallyInvisible, _a));
    var component;
    switch (type) {
        case 'email':
        case 'number':
        case 'tel':
        case 'text':
        case 'textarea':
            component = TextField;
            break;
        case 'password':
            component = PasswordField;
            break;
        case 'postalCodeAutofillCityDE':
            component = PostalCodeAutofillCityDE;
            break;
        case 'autocompletedLocality':
            component = AutocompletedLocality;
            break;
        case 'cigaretteSelection':
            component = CigaretteSelection;
            break;
        case 'ratingWithNumbers':
            component = RatingWithNumbers;
            break;
        case 'select':
            component = Select;
            break;
        case 'checkbox':
            component = Checkbox;
            break;
        case 'switch':
            component = Switch;
            break;
        case 'radio':
            component = RadioGroup;
            break;
        case 'date':
            component = DateField;
            break;
        case 'label':
            return (_jsx(Typography, __assign({ variant: labelVariant, className: className, component: "div", noMargin: noMargin }, { children: label })));
        case 'cancel':
            return (_jsx(Button, __assign({ label: label, icon: buttonIcon, onClick: onCancel, id: id, className: className }, rest)));
        case 'button':
            return (_jsx(Button, __assign({ label: label, icon: buttonIcon, isSubmitting: isProcessing, id: id, className: className }, rest)));
        case 'linkbutton':
            return (_jsx(Button, __assign({ label: label, icon: buttonIcon, isPopup: linkTarget === 'popup', id: id, className: className }, rest)));
        case 'submit':
            return (_jsx(Button, __assign({ type: "submit", label: label, icon: buttonIcon, points: buttonPoints, disabled: isSubmitDisabled, isSubmitting: isSubmitting, id: id, className: className }, rest)));
    }
    if (!component)
        return null;
    return (_jsx(Field, __assign({ component: component, name: id, type: type, className: className, helperText: helperAlwaysVisible ? helperText : null }, rest)));
}
