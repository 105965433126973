import { useEffect, useState } from 'react';
export default function useCounter(props) {
    var _a = props || {}, _b = _a.initialValue, initialValue = _b === void 0 ? 0 : _b, _c = _a.loopDuration, loopDuration = _c === void 0 ? 1 : _c, onEnded = _a.onEnded;
    var _d = useState(initialValue), timer = _d[0], setTimer = _d[1];
    useEffect(function () {
        var interval = null;
        var countdown = function () {
            if (timer > 1) {
                setTimer(function (state) { return state - 1; });
            }
            else if (timer !== 0) {
                setTimer(0);
                clearInterval(interval);
                onEnded && onEnded();
            }
        };
        interval = setInterval(countdown, loopDuration * 1000);
        return function () {
            clearInterval(interval);
        };
    }, [timer]);
    return [timer, setTimer];
}
