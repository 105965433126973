import { CXMThemeOverrides } from 'shared/core/CXMTheme'

export default function createOverrides(palette): CXMThemeOverrides {
  return {
    components: {
      // blog filter select
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: palette.text.primary + ' !important',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: palette.text.secondary,
          },
        },
      },
    },
  }
}
