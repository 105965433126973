import { RegularExpressions } from 'jticxm-constants';
import getPasswordStrength from 'shared/utils/getPasswordStrength';
import isFieldConditionTrue from 'shared/utils/isFieldConditionTrue';
var validateField = function (field, value, fields, values, settings) {
    var id = field.id, type = field.type, requiredIf = field.requiredIf;
    var required = field.required;
    if (requiredIf)
        required = isFieldConditionTrue(requiredIf);
    switch (type) {
        case 'password':
            var confirmWith = field.confirmWith, skipValidate = field.skipValidate;
            if (confirmWith)
                return value === values[confirmWith];
            if (!required || id === 'userPassword')
                return true;
            if (skipValidate && required !== '')
                return true;
            return testPassword(value, settings);
        case 'select':
            return !(required && value === '');
        case 'postalCodeAutofillCityDE':
            // validate postalCode
            return RegularExpressions.POSTAL_CODE_DE.test(value);
        default:
            return !required || !!value;
    }
};
var testPassword = function (password, settings) {
    var passwordPatterns = settings === null || settings === void 0 ? void 0 : settings.passwordPatterns;
    var passed = getPasswordStrength(password, passwordPatterns).passed;
    return passed;
};
export default validateField;
