"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const PageTemplates = {
    PP_ADDRESS: 'popup-address',
    PP_BASIC: 'popup-basic',
    PP_FLIPPABLE: 'popup-flippable',
    PP_BRAND_PREFERENCES: 'popup-brand-preferences',
    PP_CODE: 'popup-code',
    PP_COMMUNITY: 'popup-community',
    PP_DIGITAL_ID: 'popup-digital-id',
    PP_DUMMY: 'popup-dummy',
    PP_LEVEL_UPGRADE: 'popup-level-upgrade',
    PP_QUIZ: 'popup-quiz',
    PP_SURVEY: 'popup-survey',
    PP_PROFILE_FORM: 'popup-profile-form',
    PP_PROMO_CODE: 'popup-promo-code',
    PT_PACK_SAMPLING: 'page-pack-sampling',
    PT_PACK_SAMPLING_IN_STORE: 'page-pack-sampling-in-store',
    PT_PACK_SAMPLING_IN_STORE_SIGN_UP: 'page-pack-sampling-in-store-sign-up',
    PT_TRADE_PROMOTION: 'page-trade-promotion',
    PT_TRADE_PROMOTION_SIGN_UP: 'page-trade-promotion-sign-up',
    PT_TRADE_PROMOTION_VERIFY: 'page-trade-promotion-verify',
    PT_CODE_SCANNER: 'page-code-scanner',
    PT_CONTACT: 'page-contact',
    PT_CONTACT_FORM: 'page-contact-form',
    PT_AGE_CONSENT: 'page-age-consent',
    PT_DASHBOARD: 'page-dashboard',
    PT_DELETE_USER: 'page-delete-user',
    PT_FAQ: 'page-faq',
    PT_LEGAL: 'page-legal',
    PT_MESSAGE: 'page-message',
    PT_NOT_FOUND: 'page-not-found',
    PT_PROFILE: 'page-profile',
    PT_RESET_PASSWORD: 'page-reset-password',
    PT_SIGN_UP: 'page-sign-up',
    PT_TERMS: 'page-terms',
    PT_NOTIFICATION_PREFERENCES: 'page-notification-preferences',
    PT_SELF_REGISTRATION_CONFIRMATION: 'page-self-registration-confirmation',
    PT_AB_QUIZ: 'page-ab-quiz',
    PT_BLOG: 'page-blog',
    PT_BLOG_ARTICLE: 'page-blog-article',
    PT_COMMUNITY: 'page-community',
    PT_CONTENT_ARTICLE: 'page-content-article',
    PT_CONTENT_HUB: 'page-content-hub',
    PT_EXTENSION_IFRAME: 'page-extension-iframe',
    PT_GIVEAWAY: 'page-giveaway',
    PT_GUIDED_REGISTRATION: 'page-guided-registration',
    PT_LOYALTY_SYSTEM: 'page-loyalty-system',
    PT_PRE_POST_CAMPAIGN: 'pre-post-campaign',
    PT_PRODUCTS: 'page-products',
    PT_PROMO_STANDARD: 'page-promo-standard',
    PT_PROMO_STANDARD_MODULAR: 'page-promo-standard-modular',
    PT_RADIO: 'page-radio',
    PT_RANKING: 'page-ranking',
    PT_REWARD_SHOP: 'page-reward-shop',
    PT_REWARD_SHOP_CHECKOUT: 'page-reward-shop-checkout',
    PT_SANDBOX: 'page-sandbox',
    PT_WELCOME: 'page-welcome',
    PT_WIN: 'page-win',
    PT_INVITE_MEMBER: 'page-invite-member',
    PT_XMAS: 'page-xmas',
    PT_MUSIC_LEP: 'music-lep',
    PT_MUSIC_LEP_GAME: 'music-lep-game',
    PT_MUSIC_LEP_SHOP: 'music-lep-shop',
    PT_CAMEL_DASHBOARD: 'camel-dashboard',
    PT_CAMEL_GAMBLE_GAME: 'camel-gamble-game',
    PT_CAMEL_CLAW: 'camel-claw',
    PT_CAMEL_DROPIT: 'camel-dropit',
    PT_CAMEL_PINATA: 'camel-pinata',
    PT_CAMEL_PRODUCTS: 'camel-products',
    PT_CAMEL_REJUV_PACK_EXPLORER: 'camel-rejuv-pack-explorer',
    PT_NAS_DASHBOARD: 'nas-dashboard',
    PT_NAS_DONATIONS: 'nas-donations',
    PT_NAS_GAMBLE_GAME: 'nas-gamble-game',
    PT_NAS_PRODUCTS: 'nas-products',
    PT_NAS_CLAW: 'nas-claw',
    PT_NAS_PLANT_BUDDY: 'nas-plant-buddy',
    PT_NAS_ROLLING_RITUAL: 'nas-rolling-ritual',
    PT_WINSTON_DASHBOARD: 'winston-dashboard',
    PT_WINSTON_GAMBLE_GAME: 'winston-gamble-game',
    PT_WINSTON_CLAW: 'winston-claw',
    PT_WINSTON_DROPIT: 'winston-dropit',
    PT_WINSTON_PINATA: 'winston-pinata',
    PT_WINSTON_PRODUCTS: 'winston-products',
    PT_WINSTON_MUSIC_LEP: 'winston-music-lep',
    PT_WINSTON_MUSIC_LEP_GAME: 'winston-music-lep-game',
    PT_WINSTON_MUSIC_LEP_SHOP: 'winston-music-lep-shop',
    PT_SENSO_DASHBOARD: 'senso-dashboard',
    PT_BENSON_DASHBOARD: 'benson-dashboard',
};
exports.default = PageTemplates;
