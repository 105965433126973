var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import MuiTextField from '@mui/material/TextField';
import RichText from 'shared/elements/RichText';
import fieldToMuiProps from './fieldToMuiProps';
export default function Select(props) {
    var options = props.options, checkmarks = props.checkmarks, rest = __rest(props, ["options", "checkmarks"]);
    if (!options)
        return null;
    return (_jsx(MuiTextField, __assign({ select: true }, fieldToMuiProps(rest), { children: options.map(function (o, i) {
            var _a;
            return (_jsx(MenuItem, __assign({ value: o.value, disabled: o.disabled }, { children: checkmarks ? (_jsxs(_Fragment, { children: [_jsx(Checkbox, { checked: (_a = rest.value) === null || _a === void 0 ? void 0 : _a.includes(o.value), style: { pointerEvents: 'none', color: 'inherit' } }), _jsx(RichText, { content: o.label })] })) : (o.label) }), i));
        }) })));
}
