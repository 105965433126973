var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl } from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiTextField from '@mui/material/TextField';
import { useState } from 'react';
import RichText from 'shared/elements/RichText';
import HelperText from './HelperText';
import fieldToMuiProps from './fieldToMuiProps';
var Checkbox = function (props) {
    var label = props.label, className = props.className, isOther = props.isOther, onOtherInputChange = props.onOtherInputChange, rest = __rest(props, ["label", "className", "isOther", "onOtherInputChange"]);
    var muiProps = fieldToMuiProps(rest);
    var error = muiProps.error, helperText = muiProps.helperText, muiRest = __rest(muiProps, ["error", "helperText"]);
    if (isOther)
        return _jsx(CheckboxOther, __assign({}, props, { muiProps: muiProps, onOtherInputChange: onOtherInputChange }));
    var checked = props.field.value;
    return (_jsxs(FormControl, __assign({ error: error, className: className + (checked ? ' checked' : '') }, { children: [_jsx(FormControlLabel, { control: _jsx(MuiCheckbox, __assign({ checked: checked, color: "primary" }, muiRest)), label: _jsx(RichText, { content: label }) }), _jsx(HelperText, { helperText: helperText })] })));
};
function CheckboxOther(props) {
    var label = props.label, className = props.className, muiProps = props.muiProps, onOtherInputChange = props.onOtherInputChange, rest = __rest(props, ["label", "className", "muiProps", "onOtherInputChange"]);
    var error = muiProps.error, helperText = muiProps.helperText, onClick = muiProps.onClick;
    delete rest.isOther;
    var _a = useState(false), checked = _a[0], setChecked = _a[1];
    function handleClick(e) {
        setChecked(e.target.checked);
        onClick();
    }
    return (_jsxs(FormControl, __assign({ error: error, className: className + (checked ? ' checked' : '') }, { children: [_jsx(FormControlLabel, { control: _jsx(MuiCheckbox, { name: "other-checkbox", color: "primary", onClick: handleClick }), label: _jsx(MuiTextField, __assign({}, rest, { type: "text", placeholder: label, fullWidth: true, onChange: function (e) {
                        var _a = ((e === null || e === void 0 ? void 0 : e.target) || {}).value, value = _a === void 0 ? '' : _a;
                        props.form.setFieldValue(props.field.name, value);
                        onOtherInputChange();
                    } })) }), _jsx(HelperText, { helperText: helperText })] })));
}
export default Checkbox;
