var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { deDE, frFR } from '@mui/x-date-pickers/locales';
import moment from 'moment';
import { useState } from 'react';
import { useAppState } from 'shared/core/AppStateProvider';
import TextField from './TextField';
export default function DateField(props) {
    var inputFormat = 'DD.MM.YYYY';
    var initialDate = props.field.value ? moment(props.field.value) : null;
    var minDate = moment().subtract(120, 'year');
    var maxDate = moment().subtract(18, 'year');
    var _a = useState(initialDate), datePickerValue = _a[0], setDatePickerValue = _a[1];
    var _b = useAppState(), user = _b.user, isMobile = _b.isMobile, lang = _b.lang;
    var localLang = (user === null || user === void 0 ? void 0 : user.lng) ? user.lng : lang;
    var localeText = localLang === 'fr'
        ? frFR.components.MuiLocalizationProvider.defaultProps.localeText
        : deDE.components.MuiLocalizationProvider.defaultProps.localeText;
    var update = function (value) {
        if (props.form.setFieldValue) {
            props.form.setFieldValue(props.field.name, (value === null || value === void 0 ? void 0 : value.format(inputFormat)) || '');
        }
        setDatePickerValue(value);
    };
    return (_jsx(LocalizationProvider, __assign({ dateAdapter: AdapterMoment, adapterLocale: moment.locale(localLang), localeText: localeText }, { children: _jsx(DatePicker, { openTo: "year", views: ['year', 'month', 'day'], inputFormat: inputFormat, mask: isMobile ? '__.__.____' : null, value: datePickerValue, label: props === null || props === void 0 ? void 0 : props.label, onChange: function (newValue) {
                update(newValue);
            }, onOpen: function () {
                if (!props.field.value)
                    update(maxDate);
            }, renderInput: function (params) {
                var type = props.type, rest = __rest(props, ["type"]);
                return _jsx(TextField, __assign({}, rest, params));
            }, minDate: minDate, maxDate: maxDate }) })));
}
