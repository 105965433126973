var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { SocketEvents } from 'jticxm-constants';
import EventChannel from './EventChannel';
var SocketChannel = /** @class */ (function (_super) {
    __extends(SocketChannel, _super);
    function SocketChannel() {
        var _this = _super.call(this) || this;
        if (!window.io)
            return _this;
        var host = process.env.REACT_APP_API_HOST || window.location.origin;
        var connect = SocketEvents.connect, join = SocketEvents.join, otherEvents = __rest(SocketEvents, ["connect", "join"]);
        _this._socket = window.io(host, { path: "/v1/socket", transports: ['websocket'] });
        _this._socket.on(connect, function () {
            // console.log('socket connected')
            _this._socket.emit(join);
        });
        var setDispatch = function (event) {
            var name = SocketEvents[event];
            _this._socket.on(name, function (data) {
                // console.log(name, data)
                _this.dispatch({ name: name, data: data });
            });
        };
        for (var event_1 in otherEvents) {
            setDispatch(event_1);
        }
        return _this;
    }
    SocketChannel.prototype.reconnect = function () {
        if (!this._socket)
            return;
        this._socket.close();
        this._socket.open();
    };
    return SocketChannel;
}(EventChannel));
export default SocketChannel;
