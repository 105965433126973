import { jsx as _jsx } from "react/jsx-runtime";
import { SystemData } from 'jticxm-constants';
import IconButton from 'shared/components/IconButton';
import useLikes from 'shared/hooks/useLikes';
export default function StoryAdsLike(props) {
    var oid = props.oid, hasLike = props.hasLike, className = props.className;
    var _a = useLikes({
        likeItem: SystemData.LIKE_ITEM_TYPES.STORY_AD,
        oid: oid,
        hasLike: hasLike,
    }), liked = _a.liked, likeOrUnlike = _a.likeOrUnlike;
    return (_jsx(IconButton, { icon: "heart", iconStyle: liked ? 'solid' : 'light', className: className, onClick: likeOrUnlike }));
}
