var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, alpha } from '@mui/material';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import deepmerge from '@mui/utils/deepmerge';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';
import Video from 'shared/elements/Video';
import FontAwesomeIcon from '../elements/FontAwesomeIcon';
export var useVideoPlayerStyles = makeStyles(function (_a) {
    var _b, _c;
    var _d;
    var components = _a.components, breakpoints = _a.breakpoints, palette = _a.palette, spacing = _a.spacing, transitions = _a.transitions;
    return deepmerge({
        root: {
            position: 'relative',
            overflow: 'hidden',
        },
        video: {
            width: '100%',
            // minWidth: '100%',
            // minHeight: '100%',
            // position: 'relative',
            // left: '50%',
            // top: '50%',
            // transform: 'translateX(-50%) translateY(-50%)',
        },
        overlay: (_b = {
                position: 'absolute',
                zIndex: 2,
                inset: "1rem 1rem",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: 'none',
                '& > *': {
                    pointerEvents: 'auto',
                }
            },
            _b[breakpoints.up('sm')] = {
                inset: "2.4rem 2.4rem",
            },
            _b),
        iconButton: {
            width: '3.2rem',
            height: '3.2rem',
            background: alpha(palette.primary.main, 0.5),
            fontSize: '1.6rem',
            color: palette.common.white,
            borderRadius: '100%',
            transition: transitions.create(['opacity', 'transform']),
            '&:hover': {
                background: alpha(palette.primary.main, 0.6),
            },
        },
        iconButtonHidden: {
            opacity: 0,
            transform: "scale(.8)",
            pointerEvents: 'none',
        },
        playButton: (_c = {
                width: '8rem',
                height: '8rem',
                fontSize: '4rem'
            },
            _c[breakpoints.up('sm')] = {
                width: '12rem',
                height: '12rem',
                fontSize: '4.8rem',
            },
            _c),
        ui: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            display: 'flex',
            gap: spacing(1),
            transition: transitions.create('opacity'),
        },
        shyUI: {
            opacity: 0,
            pointerEvents: 'none',
        },
        pauseButton: {},
        muteButton: {},
    }, (_d = components.CxmVideoPlayer) === null || _d === void 0 ? void 0 : _d.styleOverrides);
});
export default function VideoPlayer(props) {
    var _a, _b;
    var classes = useVideoPlayerStyles(props);
    var videoRef = useRef(null);
    var theme = useTheme();
    var iconStyle = (((_a = theme.components) === null || _a === void 0 ? void 0 : _a.CxmVideoPlayer) || {}).iconStyle;
    var _c = useState(function () { return ({
        paused: !props.autoplay,
        muted: props.autoplay || props.noAudio,
    }); }), videoState = _c[0], setVideoState = _c[1];
    /** hide ui when idle (video is untouched) */
    var _d = useState(!!props.uiAutoHide), isIdle = _d[0], setIdle = _d[1];
    var onMuteClick = function () {
        if (!videoRef.current)
            return;
        videoRef.current.muted = !videoRef.current.muted;
    };
    var onPlayToggle = function () {
        if (!videoRef.current)
            return;
        videoState.paused ? videoRef.current.play() : videoRef.current.pause();
    };
    var onFullscreenClick = function () {
        if (!videoRef.current)
            return;
        videoRef.current.requestFullscreen();
    };
    /* Sync video state with video events */
    useEffect(function () {
        var video = videoRef.current;
        if (!video)
            return;
        var onEvent = function (ev) {
            setVideoState({
                paused: video.paused,
                muted: video.muted,
            });
        };
        var events = ['pause', 'playing', 'volumechange'];
        events.forEach(function (e) { return video.addEventListener(e, onEvent); });
        return function () {
            events.forEach(function (e) { return video.removeEventListener(e, onEvent); });
        };
    }, 
    // ! Keep `props.media.path` in deps. Only then it is secure to use `videoRef.current`
    [props.media.path, videoRef.current]);
    var uiAutoHideDelay = (_b = props.uiAutoHideDelay) !== null && _b !== void 0 ? _b : 3000;
    /* Show controls when video is touched */
    useEffect(function () {
        setIdle(!!props.uiAutoHide);
        var video = videoRef.current;
        if (!video || !props.uiAutoHide)
            return;
        var onDebounce = debounce(function () {
            setIdle(true);
        }, uiAutoHideDelay);
        var onTouch = function (ev) {
            setIdle(false);
            onDebounce();
        };
        video.addEventListener('mousemove', onTouch);
        video.addEventListener('touchmove', onTouch);
        return function () {
            video.removeEventListener('mousemove', onTouch);
            video.removeEventListener('touchmove', onTouch);
            onDebounce.cancel();
        };
    }, 
    // ! Keep `props.media.path` in deps. Only then it is secure to use `videoRef.current`
    [props.uiAutoHide, uiAutoHideDelay, props.media.path, videoRef.current]);
    useEffect(function () {
        var video = videoRef.current;
        if (!video)
            return;
        // Function to safely attempt to play the video
        var safelyPlayVideo = function () {
            var playPromise = video.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(function () {
                    // Playback started successfully
                })
                    .catch(function (error) {
                    console.error('Playback was prevented.', error);
                });
            }
        };
        if (props.paused) {
            video.pause();
        }
        else {
            // Only attempt to play if the video is not already playing
            if (video.paused) {
                safelyPlayVideo();
            }
        }
    }, [props.paused]);
    return (_jsxs("div", __assign({ className: classNames(classes.root, props.className) }, { children: [_jsx(Video, { mediaRef: videoRef, className: classes.video, video: props.media, autoplay: props.autoplay, muted: props.noAudio, loop: props.loop, controls: !!props.nativeControls, controlsList: props.controlsList, poster: props.poster, onClick: function () { return videoState.paused && onPlayToggle(); } }, props.media.path), !props.nativeControls && (_jsxs("div", __assign({ className: classes.overlay }, { children: [!props.hidePlay && (_jsx(IconButton, __assign({ className: classNames(classes.iconButton, !videoState.paused && classes.iconButtonHidden, classes.playButton), onClick: onPlayToggle }, { children: _jsx(FontAwesomeIcon, { name: "play", solid: iconStyle === 'solid', light: iconStyle === 'light' }) }))), _jsxs("div", __assign({ className: classNames(classes.ui, isIdle && classes.shyUI) }, { children: [!props.hidePlay && (_jsx(IconButton, __assign({ className: classNames(classes.iconButton, videoState.paused && classes.iconButtonHidden, classes.pauseButton), onClick: onPlayToggle }, { children: _jsx(FontAwesomeIcon, { name: 'pause', solid: iconStyle === 'solid', light: iconStyle === 'light' }) }))), !props.noAudio && (_jsx(IconButton, __assign({ className: classNames(classes.iconButton, videoState.paused && classes.iconButtonHidden, classes.muteButton), onClick: onMuteClick }, { children: _jsx(FontAwesomeIcon, { name: videoState.muted ? 'volume-xmark' : 'volume', solid: iconStyle === 'solid', light: iconStyle === 'light' }) }))), !props.noFullsreen && (_jsx(IconButton, __assign({ className: classNames(classes.iconButton, videoState.paused && classes.iconButtonHidden, classes.muteButton), onClick: onFullscreenClick }, { children: _jsx(FontAwesomeIcon, { name: 'expand', solid: iconStyle === 'solid', light: iconStyle === 'light' }) })))] }))] })))] })));
}
