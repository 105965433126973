import { Palette, Theme, ThemeOptions } from '@mui/material'

import { CXMThemeOverrides, createCxmTheme } from 'shared/core/CXMTheme'

import createComponents from './components'
import defaultThemeOptions from './options'
import { createTypography } from './typographies'

export default function createCamelTheme(palette?: Palette, overrides?: CXMThemeOverrides): Theme {
  return createCxmTheme({
    defaults: defaultThemeOptions as ThemeOptions,
    createTypography,
    createComponents,
    palette,
    overrides,
  })
}
