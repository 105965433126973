import CAMEL_THEME_NAMES from 'camel-de/src/theme/names'

import contrastTheme from './contrast'
import contrastAccentTheme from './contrast-accent'
import contrastOrangeTheme from './contrast-orange'
import defaultTheme from './default'
import lightTheme from './light'
import naturalTheme from './natural'

export default class CamelTheme {
  public static get(themeName) {
    switch (themeName) {
      case CAMEL_THEME_NAMES.CONTRAST:
        return contrastTheme
      case CAMEL_THEME_NAMES.LIGHT:
        return lightTheme
      case CAMEL_THEME_NAMES.NATURAL:
        return naturalTheme
      case CAMEL_THEME_NAMES.CONTRAST_ACCENT:
        return contrastAccentTheme
      case CAMEL_THEME_NAMES.CONTRAST_ORANGE:
        return contrastOrangeTheme
      default:
        return defaultTheme
    }
  }
}
