var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useApi } from 'shared/api/ApiContext';
import Link from 'shared/components/Link';
import { useStoryAds } from 'shared/components/StoryAds';
import { useRadio } from 'shared/core/Radio';
import FontAwesomeIcon from 'shared/elements/FontAwesomeIcon';
import Media from 'shared/elements/Media';
export function MenuItems(props) {
    var _this = this;
    var api = useApi();
    var items = props.items, classes = props.classes, closeMenu = props.closeMenu, toggleMenu = props.toggleMenu, open = props.open, iconsLight = props.iconsLight, hasStoryAds = props.hasStoryAds;
    var pathname = useLocation().pathname;
    var updateRadioUrl = useRadio().updateRadioUrl;
    var openStoryAds = useStoryAds().openStoryAds;
    return (_jsx(_Fragment, { children: items === null || items === void 0 ? void 0 : items.map(function (item) {
            return item.to ? (item.pageTemplate && item.pageTemplate.startsWith('popup') ? (_jsx(Link, __assign({ "data-gtm": item.to, "data-gtm-event": "menu_navigation", to: item.to, target: "popup", className: classes.link, onClick: closeMenu }, { children: _jsx(MenuItemIconAndLabel, __assign({ classes: classes, iconLight: iconsLight }, item)) }), item.id)) : (_jsx(Link, __assign({ "data-gtm": item.to, "data-gtm-event": "menu_navigation", to: item.to, className: classNames(classes.link, pathname === item.to && classes.selectedLink), onClick: closeMenu }, { children: _jsx(MenuItemIconAndLabel, __assign({ classes: classes, iconLight: iconsLight }, item)) }), item.id))) : item.api ? (_jsx("div", __assign({ className: "".concat(classes.link, " ").concat(item.id === 'story-ads' ? classes.linkWithRedDot : ''), "data-gtm": item.api, "data-gtm-event": "menu_navigation", onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                    var request, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(item.api === 'story')) return [3 /*break*/, 1];
                                openStoryAds();
                                return [3 /*break*/, 4];
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                request = api[item.api]().request;
                                return [4 /*yield*/, request];
                            case 2:
                                _a.sent();
                                if (item.api === 'logout')
                                    updateRadioUrl(null);
                                closeMenu();
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                console.error(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); } }, { children: _jsx(MenuItemIconAndLabel, __assign({ classes: classes, iconLight: iconsLight }, item)) }), item.id)) : (_jsx("div", __assign({ className: "".concat(classes.link, " ").concat(hasStoryAds && item.id === 'more-button' ? classes.linkWithRedDot : ''), onClick: toggleMenu }, { children: _jsx(MenuItemIconAndLabel, __assign({ classes: classes, iconLight: iconsLight }, item, { open: open })) }), item.id));
        }) }));
}
function MenuItemIconAndLabel(props) {
    var _a, _b, _c, _d;
    var name = props.name, icon = props.icon, _e = props.isAssetIcon, isAssetIcon = _e === void 0 ? false : _e, iconAsset = props.iconAsset, to = props.to, classes = props.classes, iconExtended = props.iconExtended, iconLight = props.iconLight, open = props.open;
    var pathname = useLocation().pathname;
    var current = pathname === to;
    var isRadioPlaying = useRadio().isRadioPlaying;
    var musicOn = to === '/radio' && isRadioPlaying();
    var displayAssetIcon = isAssetIcon && iconAsset;
    return (_jsxs(_Fragment, { children: [displayAssetIcon ? (_jsx(Media, { src: iconAsset, size: "original", className: classes.icon })) : (_jsx(FontAwesomeIcon, { className: classNames(classes.icon, (_a = {}, _a[classes.iconPlaying] = musicOn, _a), (_b = {}, _b[classes.iconExtendedOpen] = open, _b), (_c = {}, _c[classes.iconActive] = current, _c)), 
                // name={musicOn ? 'music' : icon}
                name: open ? iconExtended : icon, solid: current, light: iconLight || !current })), _jsx("span", __assign({ className: classNames(classes.name, (_d = {}, _d[classes.current] = current, _d)) }, { children: name || '⚠️ [menu name]' }))] }));
}
