import { ObjectValues } from 'jticxm-types'

const CAMEL_THEME_NAMES = {
  CONTRAST: 'contrast',
  CONTRAST_ACCENT: 'contrast-accent',
  CONTRAST_ORANGE: 'contrast-orange',
  LIGHT: 'light',
  DEFAULT: 'default',
  NATURAL: 'natural',
} as const

export default CAMEL_THEME_NAMES
export type CamelThemeName = ObjectValues<typeof CAMEL_THEME_NAMES>
