const colors = {
  midGray: '#ababab',
  lightGray: '#ccc',
  white: '#fff',
  black: '#000',

  lightBlue: '#00A3E0',
  darkBlue: '#003C71',
  origamiBlue: '#0578BE',

  lightTeal: '#0FA9BA',
  darkTeal: '#09819A',

  yellow: '#FDE600',
  red: '#C01414',
  pink: '#C63663',
  pinkLight: '#E42085',
  green: '#00AF66',
  darkGreen: '#00844D',
  orange: '#ED8B00',

  activateViolet: '#863F91',

  cnfLightBrown: '#e3c8a7',
  cnfDarkBrown: '#583123',

  // Story Ads Badge Color
  storyAdsBadge: '#D11828',
}

export default colors
