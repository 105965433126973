var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IconButton from 'shared/components/IconButton';
export default function StoryAdsOptions(props) {
    var ads = props.ads, currentAdIndex = props.currentAdIndex, classes = props.classes, close = props.close, muted = props.muted, setMuted = props.setMuted;
    var ad = ads[currentAdIndex];
    if (!ad)
        return null;
    return (_jsxs("div", __assign({ className: classes.root }, { children: [_jsx(IconButton, { icon: "close", size: "small", className: classes.closeButton, onClick: function () { return close(ad._id); } }), ad.hasAudio && (_jsx(IconButton, { icon: muted ? 'volume-slash' : 'volume', size: "small", onClick: function () { return setMuted(!muted); } }))] })));
}
