import colors from './colors'
import camelPalette from './default/palette'

export default {
  palette: camelPalette,

  brandId: 'camel',

  accordionDetailsTheme: 'light',
  extendedMenuTheme: 'contrast',
  mainMenuTheme: 'contrast',
  passwordStrengthBoxTheme: 'light',
  topBannerTheme: 'contrast',
  tutorialTheme: 'light',

  buttonIcon: {
    containedSolid: true,
  },
  circularProgress: {
    sizeContained: '2.2rem',
    sizeDefault: '2.2rem',
  },
  currencyIcon: {
    url: '/v1/img/camel-currency-icon.svg',
    height: '1em',
    verticalAlign: 'middle',
  },
  logo: {
    url: '/v1/img/camel-logo.svg',
    height: 80,
  },
  maxWidth: 1440,
  pointsBadge: {
    color: colors.white,
    backgroundColor: colors.pink,
    borderRadius: 0,
    padding: '2.5px 5px 1.5px',
    lineHeight: 1,
  },
  pointsBadgeNegative: {
    color: colors.pink,
    backgroundColor: colors.white,
    border: `1px solid ${colors.pink}`,
  },
}
