var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ApiProvider } from 'shared/api/ApiContext';
import ClientApi from 'shared/api/ClientApi';
import { InfoBoxProvider } from 'shared/components/InfoBox';
import { StoryAdsProvider } from 'shared/components/StoryAds';
import AppStateProvider from 'shared/core/AppStateProvider';
import CXMTheme from 'shared/core/CXMTheme';
import ErrorBoundary from 'shared/core/ErrorBoundary';
import PageWrapper from 'shared/core/PageWrapper';
import PopupWrapper from 'shared/core/PopupWrapper';
import RadioProvider from 'shared/core/Radio';
import ToastMessages from 'shared/core/ToastMessages';
var api = new ClientApi();
var useStyles = makeStyles(function () {
    return {
        '@global': CXMTheme.extractGlobalTypography('default'),
    };
});
export default function AppBase(props) {
    var theme = CXMTheme.get('default');
    useStyles();
    return (_jsx(BrowserRouter, { children: _jsx(ApiProvider, __assign({ api: api }, { children: _jsx(AppStateProvider, { children: _jsx(StyledEngineProvider, __assign({ injectFirst: true }, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(ErrorBoundary, { children: _jsx(ToastMessages, { children: _jsx(PopupWrapper, __assign({ templateFactory: props.templateFactory }, { children: _jsx(StoryAdsProvider, { children: _jsx(RadioProvider, { children: _jsx(InfoBoxProvider, { children: _jsx(Routes, { children: _jsx(Route, { path: "*", element: _jsx(PageWrapper, __assign({}, props)) }) }) }) }) }) })) }) }) })) })) }) })) }));
}
