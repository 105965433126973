import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import ReactProsemirrorParser from './ReactProsemirrorParser';
var RichText = memo(function (props) {
    var content = props.content, variant = props.variant, replacements = props.replacements;
    // handle labels or strings with html
    if (typeof content === 'string') {
        return ReactHtmlParser(content);
    }
    // handle richtext from database richtext fields
    return _jsx(ReactProsemirrorParser, { document: content, variant: variant, replacements: replacements });
});
export default RichText;
