export default function compileLabel(label, replacements) {
    if (!label)
        return '';
    // fail-safe
    if (typeof label !== 'string' || !replacements)
        return label;
    // Try to replace only words surrounded by curly braces {}
    return label.replace(/{\w+}/g, function (key) {
        key = key.slice(1, -1); // Remove curly braces that come from the label interpolation syntax
        return String(replacements[key]) || key;
    });
}
