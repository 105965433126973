import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import useWindowSize from 'shared/hooks/useWindowSize';
import sizeImagePath from 'shared/utils/sizeImagePath';
export default function useResponsiveSizeImagePath(props) {
    var path = props.path, defaultSize = props.size, responsiveSizes = props.responsiveSizes;
    var isResponsive = responsiveSizes && Object.keys(responsiveSizes).length > 1;
    var breakpoints = useTheme().breakpoints.values;
    var _a = useState(!isResponsive ? defaultSize : null), _size = _a[0], _setSize = _a[1];
    var windowSize = useWindowSize();
    useEffect(function () {
        if (!isResponsive || !windowSize.width)
            return;
        if (windowSize.width <= breakpoints.sm && responsiveSizes.xs) {
            _setSize(responsiveSizes.xs);
        }
        else if (windowSize.width > breakpoints.sm && windowSize.width <= breakpoints.sm && responsiveSizes.sm) {
            _setSize(responsiveSizes.sm);
        }
        else if (windowSize.width > breakpoints.sm && windowSize.width <= breakpoints.md && responsiveSizes.md) {
            _setSize(responsiveSizes.md);
        }
        else if (windowSize.width > breakpoints.md && windowSize.width <= breakpoints.lg && responsiveSizes.lg) {
            _setSize(responsiveSizes.lg);
        }
        else if (windowSize.width > breakpoints.lg && responsiveSizes.xl) {
            _setSize(responsiveSizes.xl);
        }
        else {
            _setSize(defaultSize);
        }
    }, [windowSize]);
    return _size ? sizeImagePath(path, _size) : null;
}
