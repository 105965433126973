import { Palette, alpha } from '@mui/material/styles'

import colors from '../colors'

export default {
  primary: {
    main: colors.yellow, // CTA BUTTON
    // main: Colors.lightBlue,
    light: colors.lightBlue,
    // dark: Colors.lightBlue,
    dark: colors.darkBlue,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.darkBlue, // Blog Button Text
    light: colors.white,
    dark: colors.white,
    contrastText: colors.lightBlue,
  },
  text: {
    primary: colors.white, // Default color of tag button - Select
    secondary: colors.white,
    disabled: alpha(colors.darkBlue, 0.7),
  },
  background: {
    default: colors.darkBlue, // background color of blog
    paper: colors.orange, // Select background
  },
  info: {
    main: colors.darkBlue,
    light: colors.yellow,
  },
  error: {
    main: colors.pink,
  },
  articlePreviewCard: {
    text: colors.white, // Article preview card main text
  },
  logo: {
    main: colors.darkBlue,
  },
  storyAdsBadge: {
    main: colors.storyAdsBadge,
  },
} as Palette
