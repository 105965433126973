import { darken } from '@mui/material'

import { CXMThemeOverrides } from 'shared/core/CXMTheme'

import colors from '../colors'
import typographies from '../typographies'

export default function createOverrides(palette): CXMThemeOverrides {
  return {
    typography: {
      h1: {
        color: 'currentColor',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {},
          containedPrimary: {
            backgroundColor: colors.yellow,
            color: colors.darkBlue,
            '&.Mui-disabled': {
              backgroundColor: colors.yellow,
              color: colors.darkBlue,
            },
            '&:hover, &:focus': {
              backgroundColor: darken(colors.yellow, 0.1),
              '@media (hover: none)': {
                backgroundColor: darken(colors.yellow, 0.1),
              },
            },
          },
          textPrimary: {},
        },
      },
      CxmPageXmas: {
        styleOverrides: {
          '@keyframes insetGlow': {
            '0%': {
              boxShadow: 'inset 3px 3px 12px rgba(0, 0, 0, 0.4), inset 0px 0px 0px rgba(255, 223, 0, 0)',
            },
            '50%': {
              boxShadow: 'inset 3px 3px 12px rgba(0, 0, 0, 0.4), inset 0px 0px 10px rgba(102, 178, 224, 0.9)',
            },
            '100%': {
              boxShadow: 'inset 3px 3px 12px rgba(0, 0, 0, 0.4), inset 0px 0px 0px rgba(255, 223, 0, 0)',
            },
          },
          doorBackside: {
            background: 'linear-gradient(to left, #002b50 0%, #003C71 50%, #1a5e94 100%)',
          },
          doorContents: {
            gap: '0.8rem',
            background: `radial-gradient(#ffffff21 20%, ${palette.primary.main} 80%)`,
            '& > h6': {
              fontFamily: typographies.Camel,
              color: palette.primary.contrastText,
              transform: 'scale(0.9)',
              fontSize: '1.6rem',
            },
            '& > button': {
              fontSize: '1.8rem',
              transform: 'scale(0.9)',
              padding: '1.2rem',
            },
            '& > img': {
              transform: 'scale(0.9)',
            },
          },
        },
      },
    },
  }
}
