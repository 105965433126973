var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export default function ContentModuleWrapper(props) {
    var className = props.className, innerClassName = props.innerClassName, style = props.style, children = props.children, _a = props.id, id = _a === void 0 ? undefined : _a;
    return (_jsx("section", __assign({ id: id, className: className, style: style }, { children: _jsx("div", __assign({ className: innerClassName }, { children: children })) })));
}
