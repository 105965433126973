var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, useTheme } from '@mui/styles';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CommonUrls } from 'jticxm-constants';
import FormattedNumber from 'shared/components/FormattedNumber';
import Typography from 'shared/components/Typography';
import { useAppState } from 'shared/core/AppStateProvider';
import CXMTheme, { ThemeProvider } from 'shared/core/CXMTheme';
import CurrencyIcon from 'shared/elements/CurrencyIcon';
import FontAwesomeIcon from 'shared/elements/FontAwesomeIcon';
import Media from 'shared/elements/Media';
import { TOP_BANNER_ID } from './Page';
import { MENU_WIDTH_DESKTOP } from './menu/pageMenu/PageMainMenu';
var useStyles = makeStyles(function (_a) {
    var _b, _c;
    var _d;
    var components = _a.components, palette = _a.palette, breakpoints = _a.breakpoints, transitions = _a.transitions;
    return deepmerge({
        topBanner: (_b = {
                position: 'fixed',
                top: 0,
                zIndex: 3,
                height: '2.4rem',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                background: palette.background.default,
                borderBottom: "1px solid ".concat(palette.text.primary)
            },
            _b[breakpoints.up('sm')] = {
                display: 'flex',
                alignItems: 'center',
                top: 8,
                height: '3.2rem',
                left: '50%',
                transform: "translateX(-".concat(MENU_WIDTH_DESKTOP / 2, "px)"),
                width: MENU_WIDTH_DESKTOP,
                border: 0,
                borderRadius: 5,
                boxShadow: '0px 0px 5px rgba(0,0,0,.5)',
            },
            _b),
        greeting: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '.8rem',
        },
        credits: {
            display: 'flex',
            alignItems: 'center',
        },
        '@keyframes slidefade': {
            '0%': {
                opacity: 0,
                transform: 'translateX(0)',
            },
            '50%': {
                opacity: 1,
            },
            '100%': {
                opacity: 0,
                transform: 'translateX(10px)',
            },
        },
        pointsBadgeWrapper: {
            opacity: 0,
        },
        pointsBadge: {
            display: 'inline-block',
        },
        pointsBadgeAnimated: {
            animation: "$slidefade 2s 1 ".concat(transitions.easing.easeInOut),
        },
        info: {
            padding: '0 .8rem',
            fontSize: '1.2rem',
            color: palette.text.primary,
        },
        currencyIcon: {},
        level: {
            display: 'flex',
            alignItems: 'center',
        },
        levelIcon: {
            paddingRight: '.4em',
        },
        details: {
            paddingTop: 0,
            paddingBottom: 0,
            color: palette.text.primary,
        },
        rightCornerIcon: (_c = {
                color: 'white',
                paddingRight: '0.8rem',
                paddingTop: '-5px'
            },
            _c[breakpoints.up(600)] = {
                paddingTop: 22,
                paddingRight: 10,
            },
            _c),
        pointsName: {
            marginLeft: '.3em',
        },
        seal: {},
        brandIcon: {
            position: 'relative',
        },
        brandIconContainer: {},
    }, (_d = components.CxmStatusBar) === null || _d === void 0 ? void 0 : _d.styleOverrides);
});
function StatusBar(props) {
    var _a;
    var menu = props.menu;
    var _b = useAppState(), labels = _b.labels, user = _b.user, showUserNameOnStatusBar = _b.showUserNameOnStatusBar;
    var _c = user || {}, points = _c.points, _d = _c.name, name = _d === void 0 ? '' : _d, level = _c.level;
    var classes = useStyles(props);
    var location = useLocation();
    var CxmStatusBar = useTheme().components.CxmStatusBar;
    var _e = (CxmStatusBar === null || CxmStatusBar === void 0 ? void 0 : CxmStatusBar.defaultProps) || {}, showCreditsName = _e.showCreditsName, rightCornerIconName = _e.rightCornerIconName, pageSeal = _e.pageSeal, brandIcon = _e.brandIcon;
    var _f = useState(0), pointsUpdate = _f[0], setPointsUpdate = _f[1];
    var _g = useState(false), updatingPoints = _g[0], setUpdatingPoints = _g[1];
    var _h = useState(points), lastPoints = _h[0], setLastPoints = _h[1];
    useEffect(function () {
        setLastPoints(points);
        var update = points - lastPoints;
        if (updatingPoints || isNaN(update) || update === 0 || update === points)
            return;
        setUpdatingPoints(true);
        setPointsUpdate(update);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [points]);
    if (!user || !menu)
        return null;
    return (_jsxs("aside", __assign({ id: TOP_BANNER_ID, className: classes.topBanner }, { children: [pageSeal && location.pathname === CommonUrls.ROOT && (_jsx("img", { src: pageSeal.src, alt: pageSeal.alt, className: classes.seal })), showUserNameOnStatusBar ? (_jsx(Typography, __assign({ variant: "subtitle1", color: "textSecondary", className: classes.greeting, noMargin: true }, { children: labels.GREETING + ' ' + name }))) : (_jsxs("div", __assign({ className: classes.credits }, { children: [brandIcon && (_jsx("div", __assign({ className: classes.brandIconContainer }, { children: _jsx(Media, { src: brandIcon, className: classes.brandIcon, size: "original" }) }))), _jsxs(Typography, __assign({ id: "TopBannerCredits", variant: "subtitle1", color: "textSecondary", component: "div", className: classes.info, noMargin: true }, { children: [_jsx(CurrencyIcon, { classes: { root: classes.currencyIcon } }), _jsx(FormattedNumber, { number: points }), showCreditsName && _jsx("span", __assign({ className: classes.pointsName }, { children: labels.POINTS_NAME }))] })), _jsx("div", __assign({ className: classNames(classes.pointsBadgeWrapper, (_a = {},
                            _a[classes.pointsBadgeAnimated] = updatingPoints,
                            _a)), onAnimationEnd: function () { return setUpdatingPoints(false); } }, { children: _jsxs("span", __assign({ className: classes.pointsBadge }, { children: [pointsUpdate >= 0 ? '+' : '', _jsx(FormattedNumber, { number: pointsUpdate })] })) }))] }))), level && (_jsxs(Typography, __assign({ id: "TopBannerLevel", variant: "subtitle1", color: "textSecondary", component: "div", className: classNames(classes.info, classes.level), noMargin: true }, { children: [level.icon && _jsx(FontAwesomeIcon, { name: level.icon, className: classes.levelIcon, solid: true }), level.name] }))), rightCornerIconName && (_jsx(Typography, __assign({ component: "div" }, { children: _jsx(FontAwesomeIcon, { className: classes.rightCornerIcon, name: rightCornerIconName, solid: true, size: "small" }) })))] })));
}
export default function ThemedStatusBar(props) {
    // get the from default options
    var topBannerTheme = useTheme().topBannerTheme;
    // component without theme - will inherit the theme
    if (!topBannerTheme)
        return _jsx(StatusBar, __assign({}, props));
    // themed component
    var theme = CXMTheme.get(topBannerTheme);
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(StatusBar, __assign({}, props)) })));
}
