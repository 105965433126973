var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import HelperText from './HelperText';
import fieldToMuiProps from './fieldToMuiProps';
var RadioGroup = function (props) {
    var id = props.id, className = props.className, label = props.label, _a = props.options, options = _a === void 0 ? [] : _a, onOtherInputChange = props.onOtherInputChange, rest = __rest(props, ["id", "className", "label", "options", "onOtherInputChange"]);
    var muiProps = fieldToMuiProps(rest);
    var helperText = muiProps.helperText, error = muiProps.error, muiRest = __rest(muiProps, ["helperText", "error"]);
    var value = props.form.values[props.field.name];
    return (_jsxs(FormControl, __assign({ error: error, className: className, component: "fieldset" }, { children: [_jsx(FormLabel, __assign({ component: "legend" }, { children: label })), _jsx(MuiRadioGroup, __assign({ name: id }, muiRest, { children: options.map(function (o, i) {
                    return o.isOther ? (_jsx(RadioOptionOther, { id: props.field.name, label: o.label, value: value, onOtherInputChange: onOtherInputChange }, i)) : (_jsx(FormControlLabel, { className: value === o.value ? 'checked' : '', value: o.value, label: o.label, control: _jsx(Radio, {}) }, i));
                }) })), _jsx(HelperText, { helperText: helperText })] })));
};
function RadioOptionOther(props) {
    var id = props.id, label = props.label, value = props.value, onOtherInputChange = props.onOtherInputChange;
    var _a = useState(''), otherValue = _a[0], setOtherValue = _a[1];
    return (_jsx(FormControlLabel, { value: "other-radio", className: value === 'other-radio' ? 'checked' : '', label: _jsx(TextField, { id: "".concat(id, "-other"), placeholder: label, fullWidth: true, value: otherValue, onChange: function (e) {
                var _a;
                setOtherValue((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
                onOtherInputChange();
            } }), control: _jsx(Radio, {}) }));
}
export default RadioGroup;
