import { useEffect, useState } from 'react';
export default function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    var _a = useState({
        width: undefined,
        height: undefined,
    }), _windowSize = _a[0], _setWindowSize = _a[1];
    useEffect(function () {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            _setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []); // Empty array ensures that effect is only run on mount
    return _windowSize;
}
