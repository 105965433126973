var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button';
import IconButton from 'shared/components/IconButton';
import Timer from 'shared/components/Timer';
import Typography from 'shared/components/Typography';
import CXMTheme, { ThemeProvider } from 'shared/core/CXMTheme';
var useStyles = makeStyles(function (_a) {
    var _b, _c;
    var breakpoints = _a.breakpoints, maxWidth = _a.maxWidth;
    return ({
        root: (_b = {
                position: 'relative',
                zIndex: 1,
                width: '100vw',
                maxWidth: maxWidth,
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxSizing: 'border-box',
                paddingLeft: '1.6rem',
                paddingRight: '1.6rem',
                paddingTop: '4.4rem',
                paddingBottom: '2.4rem'
            },
            _b[breakpoints.up('sm')] = {
                paddingTop: 65,
            },
            _b),
        content: {
            width: '100%',
            maxWidth: 320,
            textAlign: 'center',
        },
        overline: {
            maxWidth: 'calc(100vw - 80px)',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        closeButton: (_c = {
                position: 'absolute',
                right: 0,
                marginTop: '-1.6rem'
            },
            _c[breakpoints.up('sm')] = {
                top: 0,
                marginTop: 0,
            },
            _c),
        cta: {
            marginTop: '2rem',
            width: '100%',
        },
    });
});
export default function Countdown(props) {
    var text = props.text, backgroundColor = props.backgroundColor, deadline = props.deadline, buttonLabel = props.buttonLabel, link = props.link, theme = props.theme, onClose = props.onClose;
    var classes = useStyles();
    var navigate = useNavigate();
    var _a = useState(true), open = _a[0], setOpen = _a[1];
    var timeout = 250;
    function handleClick() {
        setOpen(false);
        setTimeout(function () {
            navigate(link);
        }, timeout);
    }
    function handleClose() {
        setOpen(false);
        onClose && onClose();
    }
    return (_jsx(ThemeProvider, __assign({ theme: CXMTheme.get(theme) }, { children: _jsx(Collapse, __assign({ in: open, timeout: timeout }, { children: _jsxs(Paper, __assign({ id: "Countdown", square: true, elevation: 0, component: "aside", className: classes.root, onClick: handleClick, style: backgroundColor ? { backgroundColor: backgroundColor } : null }, { children: [_jsx(IconButton, { icon: "close", size: "small", className: classes.closeButton, onClick: handleClose }), _jsxs("div", __assign({ className: classes.content }, { children: [_jsx(Typography, __assign({ variant: "overline", className: classes.overline }, { children: text })), _jsx(Timer, { deadline: new Date(deadline), onTimerEnd: handleClose }), _jsx(Button, { variant: "outlined", label: buttonLabel, className: classes.cta })] }))] })) })) })));
}
