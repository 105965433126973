var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { animated } from 'react-spring';
export default function StoryAdsProgress(_a) {
    var ads = _a.ads, adProgress = _a.adProgress, currentAdIndex = _a.currentAdIndex, classes = _a.classes;
    return (_jsx("div", __assign({ className: classes.root }, { children: ads.map(function (_, index) {
            var isActive = currentAdIndex === index;
            var barStateClassName = isActive ? classes.barActive : classes.barInactive;
            return (_jsx("div", __assign({ className: "".concat(classes.value, " ").concat(barStateClassName) }, { children: isActive ? (_jsx(animated.div, { style: { width: adProgress.to(function (val) { return "".concat(val, "%"); }), height: '100%' }, className: classes.bar })) : (_jsx("div", { style: { width: currentAdIndex > index ? '100%' : '0%', height: '100%' }, className: classes.bar })) }), index));
        }) })));
}
