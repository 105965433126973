var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CommonUrls } from 'jticxm-constants';
var SIGNUPSTEP_VALUE_MAP;
(function (SIGNUPSTEP_VALUE_MAP) {
    // 'signup' = '', // wont get tracked as a step
    // 'ageVerificationOptions' = '',
    SIGNUPSTEP_VALUE_MAP["ageVerification"] = "sms start";
    SIGNUPSTEP_VALUE_MAP["ageVerificationCode"] = "verification page";
    // 'username' = '',
    // 'credentials' = '',
    SIGNUPSTEP_VALUE_MAP["registerSuccess"] = "registered successfully";
    SIGNUPSTEP_VALUE_MAP["SMS_COMPLETE"] = "sms complete";
})(SIGNUPSTEP_VALUE_MAP || (SIGNUPSTEP_VALUE_MAP = {}));
var WELCOME_BOX_VALUE_MAP;
(function (WELCOME_BOX_VALUE_MAP) {
    WELCOME_BOX_VALUE_MAP["START"] = "welcome box page";
    // Count only the Start and Success Welcome events according to documentation
    // FLAVOUR = 'step2 flavour selection',
    // ADDRESS = 'step3 add an address',
    WELCOME_BOX_VALUE_MAP["ADDRESS_SUBMITTED"] = "order complete";
})(WELCOME_BOX_VALUE_MAP || (WELCOME_BOX_VALUE_MAP = {}));
var PAGE;
(function (PAGE) {
    PAGE["WELCOME"] = "welcome";
    PAGE["GOODBYE"] = "goodbye";
})(PAGE || (PAGE = {}));
var EVENT;
(function (EVENT) {
    EVENT["PAGE_VIEW"] = "page_view";
    EVENT["PRODUCT_VIEW"] = "product_view";
    EVENT["SIGN_UP_STEP"] = "sign_up_step";
    EVENT["SIGN_UP"] = "sign_up";
    EVENT["MENU_NAV"] = "menu_navigation";
    EVENT["MENU_CATEGORY"] = "menu_category";
    EVENT["CODE_PACK_SUCCESS"] = "code_pack_success";
    EVENT["CTA_CLICK"] = "cta_click";
    EVENT["FAQ_CLICK"] = "faq_click";
    EVENT["CONTACT_CLICK"] = "contact_click";
    EVENT["PLAY_GAME"] = "play_game";
    EVENT["WELCOME_BOX_START"] = "welcome_box_start";
    EVENT["WELCOME_BOX_SUCCESS"] = "welcome_box_success";
    EVENT["DELETE_ACCOUNT"] = "delete_account";
    EVENT["SELECT_CONTENT"] = "select_content";
    EVENT["VIEW_CONTENT"] = "view_content";
    EVENT["SIGN_OUT"] = "sign_out";
    EVENT["LOGIN"] = "login";
    EVENT["POPUP_VIEW"] = "popup_view";
    EVENT["EDIT_PROFILE"] = "edit_profile";
    EVENT["NEWSLETTER_SUBSCRIPTION"] = "newsletter_subscription";
    EVENT["NEWSLETTER_UNSUBSCRIPTION"] = "newsletter_unsubscription";
})(EVENT || (EVENT = {}));
var GTMManager = /** @class */ (function () {
    function GTMManager() {
    }
    GTMManager.init = function (state) {
        if (this.isInit)
            return;
        var self = this;
        var gtmId = ((state === null || state === void 0 ? void 0 : state.settings) || {}).gtmId;
        if (!gtmId)
            return console.log('🔖 GTMManager → init → disabled');
        // console.log('🔖 GTMManager → init →', gtmId)
        //@ts-ignore//
        this.dataLayer = window.dataLayer = window.dataLayer || [];
        this.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var gtmScriptTag = document.createElement('script');
        gtmScriptTag.async = true;
        gtmScriptTag.id = 'gtmScript';
        gtmScriptTag.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId; // + settings
        // gtmScriptTag.setAttribute('nonce', nonce)
        // gtmScriptTag.setAttribute('data-nonce', nonce)
        document.head.append(gtmScriptTag);
        // catch all click events
        document.addEventListener('click', catchonClickEvents);
        function catchonClickEvents(e) {
            //@ts-ignore
            var origin = e.target.closest('[data-gtm]');
            if (!origin)
                return;
            // console.log('🔖 GTMManager → catchOnClick →', origin)
            var event = origin.getAttribute('data-gtm-event') || 'cta_click';
            var cta_location = origin.getAttribute('data-gtm');
            var category = cta_location.replace('/', '');
            switch (event) {
                case EVENT.MENU_NAV:
                    self.pushData({
                        event: EVENT.MENU_NAV,
                        menu_category: category !== '' ? category : 'home',
                    });
                    break;
                case EVENT.SELECT_CONTENT:
                    self.pushData({
                        event: EVENT.SELECT_CONTENT,
                    });
                    break;
                case EVENT.CONTACT_CLICK:
                    self.pushData({
                        event: EVENT.CONTACT_CLICK,
                    });
                    break;
                case EVENT.FAQ_CLICK:
                    self.pushData({
                        event: EVENT.FAQ_CLICK,
                    });
                    break;
                case EVENT.PLAY_GAME:
                    self.pushData({
                        event: EVENT.PLAY_GAME,
                    });
                    break;
                default:
                    self.pushData({ event: event, cta_location: cta_location });
            }
        }
        this.isInit = true;
        while (this.dataStore.length)
            this.pushData(this.dataStore.shift());
    };
    GTMManager.productView = function (data) {
        try {
            var productId = data.productId, productName = data.productName, productCode = data.productCode;
            this.productId = productId;
            // Product views are tracked only for camel-de.
            Object.assign(this.pageDefaults, {
                template_name: 'page-products',
            });
            // No multiple consecutive product views
            if (this.productId !== this.lastProductId)
                this.pushData({
                    event: EVENT.PRODUCT_VIEW,
                    product_id: productId,
                    product_name: productName,
                    product_code: productCode,
                }, true);
            this.lastProductId = this.productId;
        }
        catch (err) {
            console.error('🔖  GTMManager → error', err);
        }
    };
    // TODO: get rid of location?
    GTMManager.pageView = function (location, data) {
        var _a;
        try {
            var page = data.page, user = data.user;
            var _b = page || {}, pageTemplate = _b.pageTemplate, ga_template = _b.ga_template, pageId = _b.pageId, articleDate = _b.articleDate;
            var _c = user || {}, user_id = _c.id, points = _c.points, user_language = _c.lng, newsletter = _c.newsletter;
            if (!ga_template && !pageTemplate) {
                console.warn('GTMManager.pageView failed because ga_template and pageTemplate are undefined');
                return;
            }
            var templateName = ga_template || pageTemplate.replace(/page-/, '') + '-page';
            this.pageId = pageId.toLowerCase().replace(/\s/gi, '-');
            // assign defaults
            user &&
                Object.assign(this.userDefaults, {
                    user_id: user_id,
                    user_point_balance: points.toString(),
                    user_language: user_language,
                    user_country: (_a = data === null || data === void 0 ? void 0 : data.locale) === null || _a === void 0 ? void 0 : _a.split('-')[1],
                    user_newsletter: !!newsletter ? 'yes' : 'no',
                });
            Object.assign(this.pageDefaults, __assign({ template_name: templateName }, (articleDate && { article_name: this.pageId, date: articleDate })));
            if (this.pageId !== this.lastPageId) {
                this.pushData({ event: EVENT.PAGE_VIEW });
                this.specialPageCheck(this.pageId);
                this.specialBlogCheck(articleDate);
            }
            this.lastPageId = this.pageId;
        }
        catch (err) {
            console.error('🔖  GTMManager → error', err);
        }
    };
    GTMManager.specialPageCheck = function (id) {
        switch (id) {
            case PAGE.WELCOME:
                this.pushData({ event: EVENT.WELCOME_BOX_START, welcome_box_step_name: WELCOME_BOX_VALUE_MAP.START });
                break;
            case PAGE.GOODBYE:
                this.pushData({ event: EVENT.DELETE_ACCOUNT });
                break;
        }
    };
    GTMManager.specialBlogCheck = function (hasArticleDate) {
        hasArticleDate && this.pushData({ event: EVENT.VIEW_CONTENT, article_name: this.pageId });
    };
    GTMManager.editProfile = function (data, currentBlock) {
        try {
            // Generic update profile event
            if (currentBlock !== 'settings') {
                this.pushData({ event: EVENT.EDIT_PROFILE }, true);
                return;
            }
            // Track newsletter subscription/unsubscription
            var oldSubscriptionStatus = data.currentData.signupForNewsletter, newSubscriptionStatus = data.values.signupForNewsletter;
            var subscriptionChanged = oldSubscriptionStatus !== newSubscriptionStatus;
            if (!subscriptionChanged)
                return;
            switch (newSubscriptionStatus) {
                case true:
                    Object.assign(this.userDefaults, { user_newsletter: 'yes' });
                    this.pushData({ event: EVENT.NEWSLETTER_SUBSCRIPTION }, true);
                    break;
                case false:
                    Object.assign(this.userDefaults, { user_newsletter: 'no' });
                    this.pushData({ event: EVENT.NEWSLETTER_UNSUBSCRIPTION }, true);
                    break;
            }
        }
        catch (err) {
            console.error('🔖  GTMManager → error', err);
        }
    };
    GTMManager.openPopUp = function (data) {
        var _a, _b, _c;
        try {
            if (!this.pageId)
                return; // We shouldn't trigger this before the page loads up
            var user = data.user, _d = data.component, component = _d === void 0 ? '' : _d;
            var _e = user || {}, user_id = _e.id, points = _e.points, user_language = _e.lng, newsletter = _e.newsletter;
            var template = (component === null || component === void 0 ? void 0 : component.startsWith('popup')) ? component : 'popup-basic';
            var popupSlug = (_b = (_a = data === null || data === void 0 ? void 0 : data.openPopup) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : (_c = data === null || data === void 0 ? void 0 : data.page) === null || _c === void 0 ? void 0 : _c.fullSlug;
            user &&
                Object.assign(this.userDefaults, {
                    user_id: user_id,
                    user_point_balance: points.toString(),
                    user_language: user_language,
                    user_newsletter: !!newsletter ? 'yes' : 'no',
                });
            Object.assign(this.pageDefaults, { template_name: template });
            if (popupSlug && this.lastPopupSlug !== popupSlug)
                this.pushData({ event: EVENT.POPUP_VIEW });
        }
        catch (err) {
            console.error('🔖  GTMManager → error', err);
        }
    };
    GTMManager.apiCall = function (url) {
        // triggered only if request is going through successfully
        console.log('🔖 GTMManager → apiCall → ', url);
        switch (url) {
            case CommonUrls.API_CHECK_USER:
                this.pushData({ event: EVENT.LOGIN }, true);
                break;
            case CommonUrls.API_SMS_VERIFICATION:
                this.pushData({ event: EVENT.SIGN_UP_STEP, sign_up_step_name: SIGNUPSTEP_VALUE_MAP.SMS_COMPLETE });
                break;
            case CommonUrls.API_WELCOME_BUNDLE:
                this.pushData({
                    event: EVENT.WELCOME_BOX_SUCCESS,
                    welcome_box_step_name: WELCOME_BOX_VALUE_MAP.ADDRESS_SUBMITTED,
                });
                break;
            case CommonUrls.API_REDEEM_PACK_CODE:
                this.pushData({ event: EVENT.CODE_PACK_SUCCESS });
                break;
        }
    };
    GTMManager.signUp = function (step) {
        // console.log('👉️ signUp step', step)
        var sign_up_step_name = SIGNUPSTEP_VALUE_MAP[step];
        // console.log('👉️  sign_up_step_name', sign_up_step_name)
        if (!sign_up_step_name)
            return;
        var signUpSuccess = step === 'registerSuccess';
        if (signUpSuccess) {
            this.pushData({ event: EVENT.SIGN_UP, sign_up_step_name: sign_up_step_name });
        }
        else {
            this.pushData({ event: EVENT.SIGN_UP_STEP, sign_up_step_name: sign_up_step_name });
        }
    };
    GTMManager.signOut = function () {
        this.pushData({ event: EVENT.SIGN_OUT });
        this.userDefaults = {};
        this.pageDefaults = {};
    };
    GTMManager.pushData = function (data, requiresUser) {
        if (requiresUser === void 0) { requiresUser = false; }
        if (!this.isInit)
            return this.dataStore.push(data);
        if (requiresUser && !this.userDefaults.user_id)
            return this.dataStoreUserRequired.push(data);
        if (this.userDefaults.user_id)
            while (this.dataStoreUserRequired.length)
                this.pushData(this.dataStoreUserRequired.shift());
        var dP = __assign(__assign(__assign({}, data), this.userDefaults), this.pageDefaults);
        console.log('👉️ GTM → push → ', data.event, this.pageId, dP);
        this.dataLayer.push(dP);
    };
    GTMManager.userDefaults = {};
    GTMManager.pageDefaults = {};
    GTMManager.lastPageId = '';
    GTMManager.lastPopupSlug = '';
    GTMManager.lastProductId = '';
    GTMManager.dataStore = [];
    GTMManager.dataStoreUserRequired = [];
    return GTMManager;
}());
export default GTMManager;
