var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import { Component } from 'react';
import Button from 'shared/components/Button';
import LangMenu from 'shared/components/LangMenu';
import Typography from 'shared/components/Typography';
import Page from 'shared/components/page/Page';
import { useAppState } from 'shared/core/AppStateProvider';
import ContentColumn from 'shared/elements/ContentColumn';
import RichText from 'shared/elements/RichText';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        window.gtag &&
            window.gtag('event', 'exception', {
                description: error.message,
            });
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.hasError) {
            return _jsx(GenericErrorPage, {});
        }
        return _jsx(_Fragment, { children: this.props.children });
    };
    return ErrorBoundary;
}(Component));
var useStyles = makeStyles(function (theme) { return ({
    root: {
        color: theme.palette.text.primary,
    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    copy: {
        marginTop: '4rem',
        textAlign: 'center',
    },
    buttonWrapper: {
        textAlign: 'center',
    },
    button: {
        textDecoration: 'none',
        marginTop: '1.5rem',
    },
}); });
function GenericErrorPage() {
    var classes = useStyles();
    return (_jsx(Page, __assign({ className: classes.root }, { children: _jsx(GenericError, {}) })));
}
export function GenericError() {
    var classes = useStyles();
    var _a = useAppState(), _b = _a.labels, labels = _b === void 0 ? {} : _b, user = _a.user;
    var onSwitchLanguage = function () {
        window.location.reload();
    };
    return (_jsxs(ContentColumn, __assign({ classes: { innerWrapper: classes.innerWrapper } }, { children: [!user && _jsx(LangMenu, { onSwitchLanguage: onSwitchLanguage }), _jsx(Typography, __assign({ variant: "h1" }, { children: labels.GENERIC_ERROR_PAGE_TITLE })), _jsx(Typography, __assign({ className: classes.copy }, { children: _jsx(RichText, { content: labels.GENERIC_ERROR_PAGE_COPY }) })), _jsx(Button, { variant: "contained", label: labels.GENERIC_ERROR_PAGE_HOME, className: classes.button, onClick: function () {
                    window.location.href = '/';
                } })] })));
}
export default ErrorBoundary;
