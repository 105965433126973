var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress } from '@mui/material';
import Fade from '@mui/material/Fade';
import { makeStyles } from '@mui/styles';
import { deepmerge } from '@mui/utils';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useSpring } from 'react-spring';
import { useApi } from 'shared/api/ApiContext';
import { useAppState } from 'shared/core/AppStateProvider';
import useVisibleContentHeight from 'shared/hooks/useVisibleContentHeight';
import sizeImagePath from 'shared/utils/sizeImagePath';
import StoryAdsNavControl from './StoryAdsNavControl';
import StoryAdsOptions from './StoryAdsOptions';
import StoryAdsProgress from './StoryAdsProgress';
import StoryAdsSlides from './StoryAdsSlides';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components, palette = _a.palette;
    return deepmerge({
        root: {
            position: 'fixed',
            zIndex: 1500,
            top: 0,
            left: 0,
            right: 0,
            bottom: '10vh',
            background: 'rgba(0,0,0,0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        navigation: {
            position: 'absolute',
            zIndex: 1,
            top: 0,
            right: 0,
            left: 0,
            bottom: 100,
            background: 'linear-gradient(rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)',
        },
        navControlArea: {
            position: 'absolute',
            top: 70,
            bottom: 0,
            width: '33.333%',
            cursor: 'pointer',
        },
        navControlLeft: {
            left: 0,
        },
        navControlRight: {
            right: 0,
        },
        progressRoot: {
            padding: 8,
            display: 'flex',
            justifyContent: 'space-between',
        },
        progressValue: {
            width: '100%',
            marginRight: 8,
            '&:last-child': {
                marginRight: 0,
            },
            height: '4px',
            overflow: 'hidden',
        },
        progressBar: {},
        options: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: palette.text.primary,
            padding: '0 .8rem 0 0',
            '& button': {
                color: 'inherit',
            },
        },
        pagination: {
            paddingLeft: 8,
        },
        closeButton: {
            // we need this to fix a bug from MUI
            '& > .material-icons': {
                height: '18px !important',
            },
        },
        buttonsWrapper: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            padding: '0 1.6rem 1.6rem',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%)',
        },
        linkButton: {
            flex: 1,
        },
        likeButton: {
            marginLeft: 8,
            '& .MuiIcon-root': {
                height: '100% !important',
            },
        },
        storyAdsWrapper: {
            position: 'relative',
            height: '100%',
            maxHeight: 800,
            aspectRatio: '9 / 16',
            overflow: 'hidden',
        },
        storyAd: {
            position: 'absolute',
            zIndex: 0,
            left: 0,
            transition: 'left .25s',
            height: '100%',
            width: '100%',
        },
        storyAdMedia: {
            height: '100%',
            width: '100%',
        },
        storyAdVideo: {
            height: '100%',
            objectFit: 'cover',
        },
        muteButton: {
            display: 'none',
        },
    }, (_b = components.CxmStoryAds) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
export default function StoryAds(props) {
    var _a = props.points, points = _a === void 0 ? 0 : _a;
    var ads = props.ads.filter(function (ad) { return !!(ad === null || ad === void 0 ? void 0 : ad.media) && !!(ad === null || ad === void 0 ? void 0 : ad.duration); }) || [];
    var fadeTimeout = 500; // milliseconds
    var api = useApi();
    var user = useAppState().user;
    var _b = useStoryAds(), open = _b.open, openStoryAds = _b.openStoryAds, closeStoryAds = _b.closeStoryAds;
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), resetting = _d[0], setResetting = _d[1];
    var _e = useState(0), currentAdIndex = _e[0], setCurrentAdIndex = _e[1];
    var _f = useState(true), muted = _f[0], setMuted = _f[1];
    var _g = useState(false), paused = _g[0], setPaused = _g[1];
    var _h = useState(false), isNavigating = _h[0], setIsNavigating = _h[1];
    var classes = useStyles();
    var rootRef = useRef();
    var userIdRef = useRef(user === null || user === void 0 ? void 0 : user.id);
    // using ref to get latest updated adIndex
    var currentAdIndexRef = useRef(currentAdIndex);
    useEffect(function () {
        currentAdIndexRef.current = currentAdIndex;
    }, [currentAdIndex]);
    var _j = useSpring(function () {
        var _a;
        return ({
            from: { progress: 0 },
            to: { progress: 100 },
            config: { duration: ((_a = ads[currentAdIndex]) === null || _a === void 0 ? void 0 : _a.duration) * 1000 },
            onRest: function () {
                if (currentAdIndexRef.current + 1 < ads.length) {
                    goToIndex(currentAdIndexRef.current + 1);
                }
                else {
                    close();
                }
            },
        });
    }), progress = _j[0].progress, setProgress = _j[1];
    var reset = function () {
        setResetting(true);
        setCurrentAdIndex(0);
        setPaused(false);
        setIsNavigating(false);
        setTimeout(function () {
            setResetting(false);
        }, fadeTimeout);
    };
    var close = function (oid) {
        closeStoryAds();
        reset();
        oid && api.trackStoryAdClose(oid);
    };
    var goToIndex = function (newAdIndex) {
        var _a;
        setPaused(false);
        setIsNavigating(true);
        var goToPrevIndex = function (prevIndex) {
            var _a;
            setCurrentAdIndex(prevIndex);
            setProgress({
                reset: true,
                from: { progress: 0 },
                to: { progress: 100 },
                config: { duration: ((_a = ads[prevIndex]) === null || _a === void 0 ? void 0 : _a.duration) * 1000 },
                pause: paused,
            });
        };
        if (typeof newAdIndex !== 'number') {
            return;
        }
        else if (newAdIndex <= 0) {
            // special handling is needed
            // when going back for the first slide
            goToPrevIndex(0);
        }
        else if (newAdIndex < ads.length) {
            if (newAdIndex < currentAdIndex) {
                // special handling is needed when going back
                goToPrevIndex(newAdIndex);
            }
            else {
                setCurrentAdIndex(newAdIndex);
                setMuted(true);
                setProgress({
                    reset: true,
                    from: { progress: 0 },
                    to: { progress: 100 },
                    config: { duration: ((_a = ads[newAdIndex]) === null || _a === void 0 ? void 0 : _a.duration) * 1000 },
                    pause: paused,
                });
            }
        }
        else {
            close();
        }
        setIsNavigating(false);
    };
    var handleMouseEnter = function () {
        if (!isNavigating) {
            setPaused(true);
        }
    };
    var handleMouseLeave = function () {
        if (!isNavigating) {
            setPaused(false);
        }
    };
    useVisibleContentHeight(rootRef, true);
    useEffect(function () {
        if (userIdRef.current !== (user === null || user === void 0 ? void 0 : user.id))
            userIdRef.current = user === null || user === void 0 ? void 0 : user.id;
    }, [user]);
    // Necessary to reset progress when reopening
    useEffect(function () {
        if (open) {
            goToIndex(0);
        }
    }, [open]);
    useEffect(function () {
        var loadedItems = 0;
        if (!user || !ads.length)
            return;
        openStoryAds();
        var images = ads.filter(function (ad) { var _a, _b; return ((_b = (_a = ad.media) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.split('/')[0]) === 'image'; });
        if (!images.length) {
            setLoading(false);
            return;
        }
        images.forEach(function (image, i) {
            var imageLoader = new Image();
            imageLoader.src = sizeImagePath(image.media.path, 430);
            imageLoader.onload = function () {
                loadedItems += 1;
                if (loadedItems === images.length)
                    setLoading(false);
            };
            // When an image for the story ads fails to load, remove it from the array, so it doesn't block the page
            imageLoader.onerror = function () {
                ads.splice(i, 1);
                if (loadedItems === ads.length)
                    setLoading(false);
                // If all images failed to load, immediately close the story ads. Otherwise there's a delay of 1 second before the first setTimeout hits the close check
                if (ads.length === 0)
                    close();
            };
        });
    }, [userIdRef.current]);
    // for the pausing / resuming functionality
    useEffect(function () {
        setProgress({
            pause: paused,
        });
    }, [paused, setProgress]);
    if (resetting)
        return null;
    return (_jsx(Fade, __assign({ in: open, appear: true, timeout: fadeTimeout }, { children: _jsx("aside", __assign({ id: "Story-Ads", ref: rootRef, className: classes.root }, { children: loading ? (_jsx(CircularProgress, {})) : (_jsxs("div", __assign({ className: classes.storyAdsWrapper, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onTouchStart: handleMouseEnter, onTouchEnd: handleMouseLeave }, { children: [_jsxs("nav", __assign({ className: classes.navigation }, { children: [_jsx(StoryAdsProgress, { ads: ads, adProgress: progress, currentAdIndex: currentAdIndex, classes: {
                                    root: classes.progressRoot,
                                    value: classes.progressValue,
                                    bar: classes.progressBar,
                                } }), _jsx(StoryAdsOptions, { ads: ads, currentAdIndex: currentAdIndex, classes: { root: classes.options, closeButton: classes.closeButton }, close: close, muted: muted, setMuted: setMuted }), _jsx(StoryAdsNavControl, { classes: {
                                    navControlArea: classes.navControlArea,
                                    navControlLeft: classes.navControlLeft,
                                    navControlRight: classes.navControlRight,
                                }, onLeftClick: function () { return goToIndex(currentAdIndex - 1); }, onRightClick: function () { return goToIndex(currentAdIndex + 1); } })] })), _jsx(StoryAdsSlides, { ads: ads, classes: {
                            root: classes.storyAd,
                            media: classes.storyAdMedia,
                            video: classes.storyAdVideo,
                            buttonsWrapper: classes.buttonsWrapper,
                            muteButton: classes.muteButton,
                            linkButton: classes.linkButton,
                            likeButton: classes.likeButton,
                        }, currentAdIndex: currentAdIndex, close: close, muted: muted, points: points, paused: paused })] }))) })) })));
}
var StoryAdsContext = createContext({
    open: false,
    openStoryAds: function () {
        console.log('StoryAdsContext not initialized yet');
    },
    closeStoryAds: function () {
        console.log('StoryAdsContext not initialized yet');
    },
});
export var useStoryAds = function () { return useContext(StoryAdsContext); };
export var StoryAdsProvider = function (props) {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var children = props.children;
    var openStoryAds = function () {
        setOpen(true);
    };
    var closeStoryAds = function () {
        setOpen(false);
    };
    return (_jsx(StoryAdsContext.Provider, __assign({ value: { open: open, openStoryAds: openStoryAds, closeStoryAds: closeStoryAds } }, { children: children })));
};
