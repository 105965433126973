import templateFactory from 'camel-de/src/templateFactory'
import CamelTheme from 'camel-de/src/theme'

import AppBase from 'shared/core/AppBase'
import CXMTheme from 'shared/core/CXMTheme'

CXMTheme.set(CamelTheme)

export default function App() {
  return <AppBase templateFactory={templateFactory} />
}
