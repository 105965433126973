import { Palette, alpha } from '@mui/material'

import colors from '../colors'

export default {
  primary: {
    main: colors.yellow,
    light: colors.yellow,
    dark: colors.yellow,
    contrastText: colors.darkBlue,
  },
  secondary: {
    main: colors.darkBlue,
    light: colors.darkBlue,
    dark: colors.darkBlue,
    contrastText: colors.yellow,
  },
  text: {
    primary: colors.darkBlue,
    secondary: colors.white,
    disabled: alpha(colors.darkBlue, 0.7),
  },
  background: {
    default: colors.white,
    paper: colors.white,
  },
  error: {
    light: colors.red,
    contrastText: colors.white,
  },
  storyAdsBadge: {
    main: colors.storyAdsBadge,
  },
} as Palette
