"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const RegularExpressions = {
    BIRTHDAY_1: /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/,
    BIRTHDAY_2: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    FROM_TO_DATES: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])[,]\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
    INTEGER: /^([0-9])+$/,
    MD5_HASH: /^[0-9a-f]{32}$/,
    OBJECT_ID: /^[0-9a-f]{24}$/,
    SLUG: /^([A-Za-z0-9\/_-])+$/,
    URL: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)$/,
    POSTAL_CODE_DE: /^\d{5}$/,
    MAX40CHARS: /^.{1,40}$/,
};
exports.default = RegularExpressions;
