var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
var useStyles = makeStyles(function () { return ({
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1.6rem 0 0',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: '.4rem 0 !important',
            flex: 1,
            display: 'block',
            width: '100%',
            maxWidth: '40rem',
        },
    },
}); });
export default function ButtonContainer(props) {
    var children = props.children, className = props.className, _a = props.component, component = _a === void 0 ? 'div' : _a, style = props.style;
    var classes = useStyles(props);
    var Tag = component;
    return (_jsx(Tag, __assign({ className: [classes.buttonContainer, className].join(' '), style: style }, { children: children })));
}
