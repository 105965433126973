"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POSTAL_ADDRESS_QUALITY = exports.USER_UPLOAD_STATUS = exports.GUIDED_REGISTRATION_SOURCE_TYPES = exports.REGISTRATION_SOURCE_TYPE = exports.CONTACT_INVITE_STATUS = exports.MEMBER_STATUS = void 0;
exports.MEMBER_STATUS = {
    MEMBER: 'member',
    EMAIL_RESET: 'email-reset',
    PHISHY: 'phishy',
    TEST: 'test',
    BLOCKED: 'blocked',
    DELETED: 'deleted',
};
exports.CONTACT_INVITE_STATUS = {
    FAILED: 'failed',
    MEMBER: 'member',
    NEW: 'new',
};
exports.REGISTRATION_SOURCE_TYPE = {
    FESTIVAL_GUIDED: 'festival-guided',
    FESTIVAL_SELFREGISTRATION: 'festival-self-registration',
    PACK_SAMPLING_ONLINE: 'pack-sampling-online',
    PACK_SAMPLING_IN_STORE: 'pack-sampling-in-store',
    POS_GUIDED: 'pos-guided',
    INVITED_BY_MEMBER: 'invited-by-member',
    GUIDED: 'guided',
    TRADE_PROMOTION: 'trade-promotion',
    INDEPENDENT: 'independent',
    POS_SELFREGISTRATION: 'pos-self-registration',
    E_RETAILER: 'e-retailer',
};
exports.GUIDED_REGISTRATION_SOURCE_TYPES = [
    exports.REGISTRATION_SOURCE_TYPE.FESTIVAL_GUIDED,
    exports.REGISTRATION_SOURCE_TYPE.GUIDED,
    exports.REGISTRATION_SOURCE_TYPE.PACK_SAMPLING_IN_STORE,
    exports.REGISTRATION_SOURCE_TYPE.PACK_SAMPLING_ONLINE,
    exports.REGISTRATION_SOURCE_TYPE.POS_GUIDED,
    exports.REGISTRATION_SOURCE_TYPE.TRADE_PROMOTION,
];
exports.USER_UPLOAD_STATUS = {
    APPROVED: 'approved',
    PENDING: 'pending',
    REJECTED: 'rejected',
    UNFINISHED: 'unfinished',
    SYNCED: 'synced',
};
exports.POSTAL_ADDRESS_QUALITY = {
    CERTIFIED: 'CERTIFIED',
    DOMICILE_CERTIFIED: 'DOMICILE_CERTIFIED',
    UNUSABLE: 'UNUSABLE',
    USABLE: 'USABLE',
    NONE: 'NONE',
};
