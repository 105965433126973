var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Audio from './Audio';
import Image from './Image';
import Video from './Video';
export default function Media(props) {
    var id = props.id, className = props.className, mediaRef = props.mediaRef, src = props.src, _a = props.src, _b = _a === void 0 ? {} : _a, _c = _b.type, type = _c === void 0 ? null : _c, preventVideoDownload = props.preventVideoDownload, apiTrackVideoPlay = props.apiTrackVideoPlay, rest = __rest(props, ["id", "className", "mediaRef", "src", "src", "preventVideoDownload", "apiTrackVideoPlay"]);
    if (!src) {
        console.error('Media: no src given', props);
        return null;
    }
    if (!type) {
        console.error('Media: no type given', props);
        return null;
    }
    switch (type.split('/')[0]) {
        case 'image':
            return (_jsx(Image, __assign({ image: src, className: className, mediaRef: mediaRef }, rest)));
        case 'video':
            return (_jsx(Video, __assign({ video: src, className: className, mediaRef: mediaRef, controlsList: preventVideoDownload ? 'nodownload' : null, apiTrackVideoPlay: apiTrackVideoPlay }, rest)));
        case 'audio':
            return (_jsx(Audio, __assign({ audio: src, className: className, mediaRef: mediaRef }, rest)));
    }
    return null;
}
export var FullScreenSizes = {
    xs: 600,
    sm: 960,
    md: 1280,
    lg: 'xxl',
};
