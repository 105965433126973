import { Breakpoints, Palette, alpha, darken } from '@mui/material'
import type { Components } from '@mui/material/styles'
import type { TypographyOptions } from '@mui/material/styles/createTypography'

import type { PageMainMenuStylesProps } from 'shared/components/page/menu/pageMenu/PageMainMenu'
import type { MediaSource } from 'shared/elements/Media'

import colors from './colors'
import CAMEL_THEME_NAMES from './names'
import typographies from './typographies'

export default function createComponents(
  breakpoints: Breakpoints,
  palette: Palette,
  typography: TypographyOptions,
): Components {
  return {
    CxmAccordion: {
      styleOverrides: {
        contentRoot: {
          color: palette.text.secondary,
        },
      },
    },
    CxmErrorHelperText: {
      styleOverrides: {
        iconWrapper: {
          background: palette.error.contrastText,
        },
        icon: {
          color: palette.error.light,
        },
        root: {
          color: palette.error.light,
        },
      },
    },
    CxmRewardShopTile: {
      styleOverrides: {
        dashboardBlock: {
          marginTop: '4.2rem',
          marginBottom: '3.2rem',
        },
      },
    },
    CxmRewardShopTileCard: {
      styleOverrides: {
        rewardCard: {
          boxShadow: '0px 0px 12px 0px rgba(51, 51, 51, 0.16)',
          borderRadius: 16,

          [breakpoints.down('sm')]: {
            margin: 0,
            width: '95%',
            flex: 'initial',
          },
        },
        rewardMedia: {
          borderTopRightRadius: 16,
          borderTopLeftRadius: 16,

          [breakpoints.down('sm')]: {
            aspectRatio: '4 / 3',
            maxHeight: 'initial',
            height: 'auto',
          },
        },
        rewardCardTitle: {
          fontWeight: 700,
          [breakpoints.down('sm')]: {
            textAlign: 'left',
          },
        },
        rewardCardPrice: {
          color: palette.secondary.main,
          [breakpoints.down('sm')]: {
            textAlign: 'left',
          },
        },
        rewardLikeButton: {
          color: palette.secondary.main,
        },
      },
    },
    CxmCommunityGallery: {
      styleOverrides: {
        primaryGallery: {
          color: colors.darkBlue,
          backgroundColor: colors.yellow,
        },
        secondaryGallery: {
          color: colors.darkBlue,
        },

        galleryLikeIcon: {
          color: colors.pink,
        },
        galleryLikes: {
          fontWeight: 600,
        },
        galleryMediaAuthor: {
          fontWeight: 600,
          textTransform: 'uppercase',
        },
        galleryMoreButton: {
          color: colors.darkBlue,
        },
      },
    },
    CxmCommunityPrizeBlock: {
      styleOverrides: {
        prizes: {
          backgroundColor: colors.darkBlue,
        },
      },
    },
    CxmRewardShopTileSlider: {
      styleOverrides: {
        slideContainer: {
          padding: '9px',
          margin: '0 -9px',
        },
      },
    },
    CxmContentArticlesTile: {
      styleOverrides: {
        dashboardBlock: {
          marginTop: '4.2rem',
        },
        contentArticlePreviewCard: {
          borderRadius: 16,
          boxShadow: '0px 0px 12px 0px rgba(51, 51, 51, 0.16)',
          backgroundColor: palette.background.default,
        },
        contentArticlePreviewCardMedia: {
          aspectRatio: '4 / 3',
          height: 'auto',
          [breakpoints.down('md')]: {
            height: 'auto',
          },
          [breakpoints.down('sm')]: {
            height: 'auto',
          },
        },
        contentArticleCategory: {
          color: palette.text.secondary,
          backgroundColor: palette.secondary.main,
        },
        contentArticlePreviewCardText: {
          color: palette.secondary.main,
          minHeight: '6.5rem',
        },
      },
    },
    CxmContentArticlesTileSlider: {
      styleOverrides: {
        slideContainer: { margin: '0 -9px', padding: '9px' },
      },
    },
    CxmQuoteTile: {
      mobileBackground: { path: '/v1/img/camel-quote-mobile.jpg', type: 'image/jpg' },
      desktopBackground: { path: '/v1/img/camel-quote-desktop.jpg', type: 'image/jpg' },
      bubbleIcon: { path: '/v1/img/camel-icon.svg', type: 'image/svg' },
      styleOverrides: {
        dashboardBlock: {
          marginTop: '4.2rem',
        },
        quoteContainer: {
          backgroundImage: ({
            mobileBackground,
            desktopBackground,
            mobile,
          }: {
            mobileBackground: MediaSource
            desktopBackground: MediaSource
            mobile: boolean
          }) =>
            mobileBackground || desktopBackground
              ? `url(${mobile ? mobileBackground.path : desktopBackground.path})`
              : null,
          backgroundSize: 'cover',
          padding: '6.4rem 1.6rem 2.4rem 1.6rem',
          borderRadius: 16,
        },
        quoteHeader: {
          marginTop: 0,
          height: 'auto',
          marginBottom: '2.4rem',
          backgroundColor: 'transparent',
          maxWidth: '69.6rem',
        },
        quote: {
          alignSelf: 'auto',
          marginBottom: '1.2rem',
          textAlign: 'center',
          maxWidth: '69.6rem',
        },
        quoteAuthor: {
          alignSelf: 'auto',
          paddingBottom: '1.6rem',
          fontStyle: 'normal',
          textTransform: 'initial',
          maxWidth: '69.6rem',
        },
        shareIcon: {
          borderRadius: '50%',
          padding: '1.6rem 1.5rem 1.2rem 1.3rem',
          backgroundColor: palette.secondary.main,
          color: palette.primary.main,
        },
      },
    },
    CxmLegalMenu: {
      openLinksInNewTab: true,
      styleOverrides: {
        legalMenu: {
          position: 'relative',
          zIndex: 1,
          backgroundColor: colors.darkBlue,
          '& > a:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    CxmLink: {
      styleOverrides: {
        link: {
          color: palette.text.primary,
          '&:hover': {
            textDecoration: `underline ${palette.text.primary} 2px`,
          },
          '& > a': {
            color: palette.text.primary,
            '&:hover': {
              textDecoration: `underline ${palette.text.primary} 2px`,
            },
          },
        },
      },
    },
    CxmPageExtendedMenu: {
      styleOverrides: {
        link: {
          '&:hover': {
            textDecoration: 'none !important',
          },
        },
      },
    },
    CxmPageFooter: {
      styleOverrides: {
        warning: {
          minHeight: 80,
          fontSize: '16px !important',
          borderTop: '2px solid #000',
          borderBottom: '2px solid #000',

          '&:empty': {
            display: ({ user }: { user: boolean }) => (user ? null : 'none'),
            minHeight: 0,
            padding: 0,
            borderBottom: 'none',
          },
        },
      },
    },
    CxmPageMainMenu: {
      styleOverrides: {
        menu: {
          fontSize: '1.2rem',
          [breakpoints.up('sm')]: {
            bottom: ({ hwStatic, hasHealthWarning }: PageMainMenuStylesProps) => {
              if (hasHealthWarning && !hwStatic) return 110
              if (!hasHealthWarning && !hwStatic) return 30
              return null
            },
          },
        },
        link: {
          '&:hover': {
            textDecoration: 'none !important',
          },
        },
      },
    },
    CxmStatusBar: {
      defaultProps: {
        brandIcon: { path: '/v1/img/camel-icon.svg', type: 'image/svg' },
      },
      styleOverrides: {
        topBanner: {
          overflow: 'hidden',
        },
        brandIconContainer: {
          backgroundColor: palette.primary.main,
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'relative',
            height: '35px',
            width: '6px',
            backgroundColor: palette.primary.main,
            zIndex: 3,
            left: '3px',
            transform: 'rotate(10deg)',
          },
        },
        brandIcon: {
          padding: '4px 0px 4px 6px',
          height: '16px',
        },
        pointsBadge: {
          fontFamily: typographies.Camel,
          fontSize: '1.4rem',
          color: colors.white,
          padding: '0.3em 0.6em',
          lineHeight: 1,
          display: 'inline-block',
          position: 'relative',
          zIndex: 1,

          '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: colors.pink,
            transform: 'skewX(-10deg)',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            width: '100%',
            height: '100%',
          },
        },
        info: {
          fontSize: '1.3rem',
          fontWeight: 500,
          // currency icon
          '& > span': {
            position: 'relative',
            top: -1,
          },
        },
      },
    },
    CxmPageWrapper: {
      styleOverrides: {
        pageRoot: {
          backgroundColor: palette.common.white,
        },
        warning: {
          fontWeight: 'normal',
        },
        radioIframe: {
          borderRadius: '0',
        },
      },
    },
    CxmPageABQuiz: {
      styleOverrides: {
        optionA: {
          '& > div': {
            justifyContent: 'flex-start',
          },

          '&:before': {
            content: '""',
            position: 'absolute',
            left: '-20%',
            top: 0,
            zIndex: 0,
            width: '120%',
            height: '100%',
            transform: 'skewX(-10deg)',
          },
        },

        optionB: {
          '& > div': {
            justifyContent: 'flex-end',
            textAlign: 'right',
          },

          '&:before': {
            width: '120%',
            height: '100%',
            transform: 'skewX(-10deg)',
          },
        },

        answerText: {
          fontFamily: typographies.Camel,
          fontSize: '1.6rem',
          fontWeight: 'bold',
        },

        selectorContainer: {
          overflow: 'hidden',
        },
      },
    },
    CxmPageContactForm: {
      hideGoBack: true,
    },
    CxmPageLegal: {
      hideGoBack: true,
    },
    CxmPagePackSampling: {
      styleOverrides: {
        productContainer: {
          backgroundColor: colors.darkBlue,
        },
        productRadio: {
          border: `2px solid ${colors.yellow}`,
          '&::after': {
            backgroundColor: colors.yellow,
          },
        },
        productDescription: {
          display: 'block',
        },
        // productImage: {
        //   height: 370,
        //   aspectRatio: '4 / 3',
        // },
        productTitle: {
          color: palette.primary.main,
          marginTop: '2.4rem',
          // ...typography.h3,
          ...typography.h6,
          fontFamily: typographies.Camel,
        },
        successTitle: {
          color: palette.primary.main,
        },
      },
    },
    CxmPageProducts: {
      themes: {
        root: {
          pink: {
            backgroundColor: colors.pinkLight,
          },
          orange: {
            backgroundColor: colors.orange,
          },
          'dark-blue': {
            backgroundColor: colors.darkBlue,
          },
          'light-blue': {
            backgroundColor: colors.lightBlue,
          },
        },
        title: {},
        numbers: {
          pink: {
            color: colors.white,
            backgroundColor: colors.lightTeal,
          },
        },
      },
      styleOverrides: {
        tabsWrapper: {
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '3.2rem',
        },
        tab: {
          textTransform: 'uppercase',
          padding: '0 0.2em',
          margin: '0 2rem',
          cursor: 'pointer',
        },
        tabSelected: {
          borderBottom: '2px solid currentColor',
        },
        tabDescription: {
          marginBottom: '1.2rem',
        },

        productImage: {
          [breakpoints.up('sm')]: {
            maxHeight: 360,
          },
        },

        group: {},
        groupTitle: {
          color: 'inherit',
          textAlign: 'center',
          paddingTop: '2.4rem',
        },
        groupDescription: {
          marginBottom: '3.2rem',
          '& > *': {
            color: 'inherit',
          },
        },

        sliderWrapper: {
          margin: '0 auto',
          paddingBottom: '2.4rem',
          [breakpoints.up('sm')]: {
            maxWidth: 360,
          },
        },
        slideContainer: {
          paddingBottom: '3.2rem',
        },
        stepper: {
          top: 'calc(50vw - 2.4rem)',
          [breakpoints.up('sm')]: {
            top: 'calc(180px - 2.4rem)',
          },
        },
        stepperDots: {
          display: 'none',
        },
        stepperBackButton: {
          left: '1rem',
          backgroundColor: `${alpha(palette.primary.contrastText, 0.6)} !important`,
        },
        stepperNextButton: {
          right: '1rem',
          backgroundColor: `${alpha(palette.primary.contrastText, 0.6)} !important`,
        },
      },
      footerNoMargin: true,
      displayDetailsInPopup: true,
    },
    CxmPagePromoStandardModular: {
      contentColumnWidth: 696,
      styleOverrides: {
        contentModulePrizeBlockTitle: {
          marginTop: '3.2rem',
          textAlign: 'center',
        },
        mainBlockTitle: {
          marginTop: '3.6rem',
          textAlign: 'center',
          textTransform: 'uppercase',
        },
        contentModuleMedia: {
          width: '100%',
          paddingBottom: '1.6rem',
        },
        contentModuleRaffle: {
          padding: '0 1.6rem 4.4rem',
          marginBottom: 0,
          [breakpoints.up('xs')]: {
            padding: '0 2.4rem 4.4rem',
          },
          '& > *': {
            marginLeft: 'auto',
            marginRight: 'auto',
            [breakpoints.up('md')]: {
              maxWidth: '66.666667%',
            },
          },
        },
        ranking: {
          maxWidth: '57rem',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        rankingTable: {
          maxWidth: '40rem',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        rankingTab: {
          margin: '3.2rem 1.6rem 1.2rem',
        },
      },
    },
    CxmPageRewardShop: {
      defaultProps: {
        groupBundles: true,
        pageTheme: CAMEL_THEME_NAMES.LIGHT,
      },
    },
    CxmPageRewardVariantShopCheckout: {
      defaultProps: {
        pageTheme: CAMEL_THEME_NAMES.LIGHT,
      },
      styleOverrides: {
        rewardHint: {
          backgroundColor: alpha(palette.secondary.main, 0.1),
          border: `1px solid ${alpha(palette.secondary.main, 0.2)}`,
          color: palette.secondary.main,
        },
        rewardMediaWrapper: {
          aspectRatio: 'initial',
        },
        rewardHintText: {
          fontWeight: 600,
          '& span': {
            color: colors.pink,
          },
        },
        rewardHintLink: {
          color: colors.pink,
        },
        // Variants
        variantTitle: {
          fontWeight: 600,
        },
        colorVariantInner: {},
        colorVariantInnerActive: {
          outline: `3px solid ${palette.secondary.main}`,
        },

        sizeVariantInner: {
          color: palette.secondary.main,
          borderColor: palette.secondary.main,
          fontWeight: 600,
        },
        sizeVariantInnerActive: {
          color: palette.text.primary,
          backgroundColor: palette.secondary.main,
        },
        sizeVariantInnerDisabled: {
          // Backslash for disabled items, responsive.
          background: `linear-gradient(to bottom left, transparent calc(50% - 1px),
             ${alpha(colors.darkBlue, 0.6)} calc(50% - 1px), ${alpha(
            colors.darkBlue,
            0.6,
          )} 50%, transparent 50%)`,
        },
      },
    },
    CxmPopupQuiz: {
      styleOverrides: {
        type_radio: {
          '& label': {
            color: colors.darkBlue,
            backgroundColor: colors.white,
          },

          '&.wrong-answer label.checked': {
            color: colors.white,

            '& span': {
              color: colors.white,
            },

            '&:after': {
              backgroundColor: colors.red,
            },
          },

          '&.correct-answer label.checked': {
            color: colors.white,

            '& span': {
              color: colors.white,
            },

            '&:after': {
              backgroundColor: colors.darkGreen,
            },
          },
        },
      },
    },
    CxmBlogPreview: {
      styleOverrides: {
        contentArticlePreviewCard: {
          backgroundColor: colors.darkBlue,
          minHeight: '36rem',
        },
        contentArticlePreviewCardText: {
          backgroundColor: colors.darkBlue,
        },
        contentArticleCategory: {
          color: colors.darkBlue,
        },
      },
    },
    CxmPollComponent: {
      styleOverrides: {
        pollFormWrapper: {
          backgroundColor: colors.lightBlue,
          borderRadius: 0,
          minHeight: 0,
        },
        pollButtonContainer: {
          border: 'none',
          borderRadius: 0,
        },
        resultsWrapper: {
          height: 'auto',
          minHeight: 240,
          padding: '1rem 1.6rem',
          backgroundColor: 'transparent',
        },
        resultWrapper: {
          width: '100%',
          borderRadius: 0,
          color: '#FFFFFF',
          background: '#668AAA',
        },
        resultBar: {
          background: colors.darkBlue,
        },
        pollForm: {
          width: '100%',
        },
        formCheckbox: {
          borderRadius: 0,
          color: colors.black,
          backgroundColor: '#FFFFFF',
          '&:before': {
            backgroundColor: colors.darkBlue,
          },
          '&.checked .MuiTypography-root, & .Mui-checked': {
            color: colors.white,
          },
        },
        formRadio: {
          '& label': {
            color: colors.darkBlue,
            backgroundColor: colors.white,
            borderRadius: 0,
            padding: '1rem 1.2rem 1rem 0',
            '&:before': {
              backgroundColor: colors.darkBlue,
            },
            '& .MuiRadio-root': {
              color: colors.darkBlue,
            },
            '&.checked .MuiTypography-root, & .Mui-checked': {
              color: colors.white,
            },
          },
        },
      },
    },
    CxmSliderProduct: {
      styleOverrides: {
        product: {
          display: 'flex',
          flexDirection: 'column',
        },
        productTitle: {
          color: 'inherit',
          textAlign: 'center',
          padding: '0 1.6rem',
        },
        productDescription: {
          marginBottom: '2.4rem',
          '& > *': {
            color: 'inherit',
          },
        },
        productImage: {
          width: '100%',
          marginBottom: '2.4rem',
        },
        productNumbersWrapper: {
          display: 'flex',
          margin: 'auto',
          textAlign: 'center',
          color: palette.primary.contrastText,
          backgroundColor: palette.primary.main,
          padding: '.8rem',
          transform: 'skew(-10deg)',
          boxShadow: '10px 10px 20px rgba(0,0,0,0.2)',
          '&:empty': {
            display: 'none',
          },
        },
        productNumber: {
          minWidth: '6rem',
          padding: '.8rem',
          whiteSpace: 'nowrap',
          '& > p': {
            color: 'inherit',
          },
          '& > p:nth-child(1)': {
            borderBottom: '1px solid currentColor',
            paddingBottom: 6,
            marginBottom: 6,
          },
        },
        productNotice: {
          color: 'inherit',
          padding: '3.2rem 1.6rem 0',
        },
      },
    },
    CxmStoryAds: {
      styleOverrides: {
        navigation: {
          color: colors.white,
        },
        progressValue: {
          backgroundColor: alpha(colors.white, 0.3),
        },
        progressBar: {
          backgroundColor: colors.white,
        },
        options: {
          color: colors.white,
        },
        likeButton: {
          color: colors.white,
        },
      },
    },
    CxmTeaserBlock: {
      subtitleVariant: 'body2',
      tagVariant: 'subtitle1',
      styleOverrides: {
        teaserBlock: {
          boxShadow: '0px 0px 12px 0px rgba(51, 51, 51, 0.16)',
          borderRadius: '1.6rem',
          margin: '0 16px',

          [breakpoints.between('sm', 'lg')]: {
            flexDirection: 'column',
          },

          [breakpoints.between('sm', 'md')]: {
            '&:first-child > article': {
              paddingTop: '4rem',
            },
          },
        },
        teaserBlockMedia: {
          position: 'relative',
          borderTopRightRadius: '16px',
          borderTopLeftRadius: '16px',
          aspectRatio: '1 / 1',
          height: 'initial',

          [breakpoints.between('sm', 'lg')]: {
            flex: 'auto',
            aspectRatio: 'auto',
            width: '100%',
            height: '50vw',
            '&:after': {
              content: 'initial',
            },
          },

          [breakpoints.up('lg')]: {
            aspectRatio: 'auto',
            borderRadius: '16px',
            '&:after': {
              content: '""',
              display: 'block',
              height: '100%',
              width: 160,
              transform: 'skewX(-10deg)',
              backgroundColor: ({ backgroundColor }: any) => (backgroundColor ? backgroundColor : null),
              position: 'absolute',
              top: 0,
              right: -80,
              zIndex: 0,
            },
          },
        },
        teaserBlockTag: {
          '& span': { alignSelf: 'center' },
          paddingLeft: '3px',
          alignItems: 'initial',
          [breakpoints.between('sm', 'lg')]: {
            justifyContent: 'center',
          },
        },
        teaserBlockMediaRight: {
          [breakpoints.between('sm', 'lg')]: {
            // Necessary to avoid problems with flex direction
            order: 'initial !important',
          },

          [breakpoints.up('sm')]: {
            '&:after': {
              right: 'auto',
              left: -80,
            },
          },
        },
        teaserBlockContentWrapper: {
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          position: 'relative',
          zIndex: 1,

          [breakpoints.up('sm')]: {
            width: '100%',
            height: 'auto',
            maxHeight: 'initial',
            minHeight: '0px',
            borderTopRightRadius: '0px',
            borderTopLeftRadius: '0px',
          },

          [breakpoints.up('lg')]: {
            borderRadius: '16px',
            borderBottomRightRadius: 'auto',
            borderBottomLeftRadius: 'auto',

            width: '33%',
            height: '60vw',
            maxHeight: '46.4rem',
            // maxHeight: maxWidth / 2,

            textAlign: 'left',
            padding: '2.4rem 0',
            '& > *': {
              width: 'calc(100% - 2.4rem)',
            },
          },
        },
        teaserBlockContentLeft: {
          [breakpoints.up('md')]: {
            alignItems: 'center',
            paddingLeft: '1.6rem',
          },
        },
        teaserBlockButtonContainer: {
          [breakpoints.up('md')]: {
            alignItems: 'flex-start',
          },

          [breakpoints.between('sm', 'lg')]: {
            width: '100%',
            alignItems: 'center',
          },
        },
        teaserBlockTitle: {
          [breakpoints.down('sm')]: {
            textAlign: 'left',
          },
        },
        teaserBlockSubtitle: {
          [breakpoints.down('sm')]: {
            textAlign: 'left',
          },
        },
      },
    },
    CxmPageXmas: {
      imagesPath: `/v1/img/promotions/xmas-camel`,
      styleOverrides: {
        logo: {
          display: 'none',
        },
        poster: {
          backgroundPosition: 'bottom center !important', // Overrides inline style, needed because of the snow pseudo element
          marginBottom: '4rem',
          position: 'relative',
          '&:after': {
            width: '100%',
            bottom: '-28vw',
            height: '50vw',
            content: '""',
            position: 'absolute',
            background: 'url(/v1/img/promotions/xmas-camel/camel-snow.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            [breakpoints.up(1440)]: {
              maxHeight: '725px', // Prevent the image from shifting on bigger screens
              bottom: '-403px', // Sadly need to have a solid value here when we hit max screens size
            },
          },
        },
        title: {
          marginBottom: 0,
        },
        subtitle: {
          color: colors.darkBlue,
        },
        doorBackside: {
          borderRadius: '19px',
        },
        doorContents: {
          '& > h6': {
            hyphens: 'auto',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          color: alpha(palette.text.primary, 0.7),
          '&.Mui-error': {
            color: 'inherit',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& > label': {
              color: palette.text.primary,
            },
          },
          // '&:focus': {
          //   '& > label': {
          //     color: palette.text.primary,
          //   },
          // },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: typographies.Graphik,
          fontSize: '1.8rem',
          lineHeight: 1,
          color: 'inherit',
          letterSpacing: 'normal',
          paddingTop: '0.2em',
          '&.Mui-disabled': {
            color: 'inherit',
            opacity: '0.7 !important',
          },
          '&.Mui-focused': {
            color: 'inherit',
          },
        },
        asterisk: {
          color: 'inherit !important',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.6rem',
          lineHeight: '1.3',
          '& > a': {
            color: 'currentColor',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: typographies.Graphik,
          fontSize: '1.6rem',
          color: palette.text.primary,

          '&.Mui-disabled': {
            opacity: 0.7,
          },
        },
        input: {
          fontSize: '1.8rem',
          lineHeight: '1.35',
          letterSpacing: 'normal',
          // fix chrome, safari an edge
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
          },
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
          },
          // fix for firefox
          '&[type=number]': { MozAppearance: 'textfield' },
          // fix for autofill
          '&:-webkit-autofill, &:-webkit-autofill:hover,  &:-webkit-autofill:focus,  &:-webkit-autofill:active': {
            WebkitBoxShadow: `0 0 0 30px ${palette.background.default} inset !important`,
            WebkitTextFillColor: palette.text.primary + ' !important',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          color: palette.text.primary,
          '&.Mui-disabled': {
            '&:before': {
              borderBottomStyle: 'solid !important',
            },
          },
        },
        underline: {
          '&.Mui-error:before': {
            borderBottomColor: 'inherit',
          },
          '&:before': {
            borderBottomWidth: '2px !important',
            borderBottomColor: 'inherit',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          color: palette.text.primary,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          height: 'auto',
          width: 'auto',
          color: palette.text.primary,
          backgroundColor: 'transparent',
          borderRadius: 4,
          padding: 4,
          margin: 5,
          '&:hover': {
            backgroundColor: alpha(palette.text.primary, 0.1),
          },
          '&.Mui-checked': {
            color: palette.text.primary,
          },
          '&.Mui-disabled': {
            opacity: 0.5,
            color: palette.text.primary,
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: palette.text.primary,
          fontSize: '2.4rem',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: { width: 'auto', height: 'auto' },
        colorSecondary: {
          color: palette.text.primary,
          '&.Mui-checked': {
            color: palette.text.primary,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: typographies.Graphik,
          fontSize: '1rem',
          lineHeight: 1.25,
          marginTop: 5,
          marginLeft: 0,
          marginRight: 0,
          padding: '0 0.2em',
          '&.Mui-error': {
            color: 'inherit',
          },
          '& > a': {
            color: 'currentColor',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: typographies.Graphik,
          fontSize: '1.8rem',
          lineHeight: '2.2rem',
          hyphens: 'auto',
          borderRadius: 0,
          overflow: 'hidden',
          textAlign: 'center',
          transition: 'transform 0.1s, background-color 0.1s',
          '&:active': {
            transform: 'scale(0.98)',
          },
          '&.Mui-disabled': {
            opacity: '0.7',
          },
        },
        containedPrimary: {
          fontFamily: typographies.Camel,
          fontVariantLigatures: 'no-common-ligatures',
          fontWeight: 'bold',
          fontSize: '2rem',
          padding: '1.3rem 1.6rem',
          color: palette.secondary.main,
          backgroundColor: palette.primary.main,
          '&:hover': {
            backgroundColor: darken(palette.primary.main, 0.1),
            '@media (hover: none)': {
              backgroundColor: darken(palette.primary.main, 0.1),
            },
          },
          '&:focus': {
            backgroundColor: darken(palette.primary.main, 0.1),
            '@media (hover: none)': {
              backgroundColor: darken(palette.primary.main, 0.1),
            },
          },
          '&.Mui-disabled': {
            color: palette.secondary.main,
            backgroundColor: palette.primary.main,
          },
        },
        outlinedPrimary: {
          padding: '1.1rem 1.6rem',
          color: palette.text.primary,
          borderWidth: 2,
          borderColor: 'currentColor',
          transition: 'transform 0.1s',
          '&:hover': {
            borderColor: 'currentColor',
            borderWidth: 2,
            backgroundColor: alpha(palette.text.primary, 0.1),
            '@media (hover: none)': {
              backgroundColor: alpha(palette.text.primary, 0.1),
            },
          },
          '&:focus': {
            borderColor: 'currentColor',
            borderWidth: 2,
            backgroundColor: alpha(palette.text.primary, 0.1),
            '@media (hover: none)': {
              backgroundColor: alpha(palette.text.primary, 0.1),
            },
          },
          '&.Mui-disabled': {
            color: palette.text.primary,
            borderColor: 'currentColor',
            borderWidth: 2,
          },
          '& > span > span[class*=icon]': {
            position: 'relative',
            top: -1,
          },
        },
        textPrimary: {
          padding: '1.3rem 1.6rem',
          textDecoration: 'underline',
          verticalAlign: 'unset',
          color: palette.text.primary,
          '&.Mui-disabled': {
            color: palette.text.primary,
          },
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: alpha(palette.text.primary, 0.1),
            '@media (hover: none)': {
              backgroundColor: alpha(palette.text.primary, 0.1),
            },
          },
          '&:focus': {
            textDecoration: 'underline',
            backgroundColor: alpha(palette.text.primary, 0.1),
            '@media (hover: none)': {
              backgroundColor: alpha(palette.text.primary, 0.1),
            },
          },
        },
        containedSizeSmall: {
          padding: 10,
          fontSize: 14,
          lineHeight: '16px',
        },
        outlinedSizeSmall: {
          padding: '9px 10px 7px',
          fontSize: 14,
          lineHeight: '16px',
        },
        textSizeSmall: {
          padding: '11px 10px 9px',
          fontSize: 14,
          lineHeight: '16px',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: '3.2rem',
          width: '4.8rem',
          height: '4.8rem',
          color: palette.text.primary,
          borderRadius: 0,
          transition: 'transform 0.1s, background-color 0.1s',
          '&:active': {
            transform: 'scale(0.98)',
          },
          '&:hover': {
            backgroundColor: alpha(palette.text.primary, 0.1),
          },
          '&.Mui-disabled': {
            opacity: '0.7',
          },
        },
        sizeSmall: {
          fontSize: '2.4rem',
          width: '4rem',
          height: '4rem',
        },
        colorPrimary: {
          color: palette.secondary.main,
          backgroundColor: palette.primary.main,
          '&:hover': {
            backgroundColor: darken(palette.primary.main, 0.1),
          },
          '&.Mui-disabled': {
            color: palette.secondary.main,
            backgroundColor: palette.primary.main,
          },
        },
        colorSecondary: {
          color: palette.text.primary,
          '&:hover': {
            backgroundColor: alpha(palette.text.primary, 0.1),
          },
          '&.Mui-disabled': {
            color: palette.text.primary,
          },
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          width: 'auto',
          fontSize: 'inherit',
        },
        fontSizeLarge: {
          fontSize: '3.2rem',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '2rem',
          height: '2rem',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: { width: 'auto', height: 'auto', borderRadius: '50%' },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          padding: '2rem',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          color: 'inherit',
        },
        dots: {
          alignItems: 'center',
        },
        dot: {
          backgroundColor: 'transparent',
          borderWidth: 1,
          borderColor: 'currentColor',
          borderStyle: 'solid',
          margin: '0 1.5rem',
        },
        dotActive: {
          backgroundColor: 'currentColor',
          height: '1.2rem',
          width: '1.2rem',
        },
        positionTop: {
          position: 'absolute',
          zIndex: 1,
        },
        positionBottom: {
          position: 'absolute',
          zIndex: 1,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: alpha(palette.text.primary, 0.3),
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: '1500 !important',
        },
      },
    },
    // rating with numbers
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '&.rating-with-numbers': {
            flexDirection: 'row',
            '& > label': {
              flex: 1,
              margin: 0,
            },
            '& [class*=MuiButton]': {
              display: 'none',
            },
            '& span': {
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '4.6rem',

              fontFamily: typographies.Graphik,
              fontSize: '1.8rem',
              fontWeight: 600,
              lineHeight: 1,
              letterSpacing: 0,
            },
            '& [class*=checked] + span': {
              backgroundColor: palette.text.primary,
              color: palette.text.secondary,
            },
          },
        },
      },
    },
    // tables
    MuiTable: {
      styleOverrides: {
        root: {
          marginBottom: '2.4rem',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '1.6rem',
          borderBottomColor: 'currentColor',
          '&:first-child': {
            paddingLeft: 0,
          },
          '&:last-child': {
            paddingRight: 0,
          },
          '& > p': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
          border: `2px solid ${palette.text.primary}`,
          borderRadius: 0,
          height: '3.2rem',

          ...typography.overline,
          display: 'inline-flex',
        },
        filled: {
          backgroundColor: palette.text.primary,
          '&:focus, &:hover': {
            backgroundColor: palette.text.primary,
          },
        },
        outlined: {
          color: palette.text.primary,
        },
      },
    },
    MuiDatePickerToolbar: {
      styleOverrides: {
        root: {
          '& h4': {
            color: palette.text.primary,
          },
          '& button': {
            color: palette.text.primary,
          },
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        weekDayLabel: {
          color: palette.text.primary,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& button': {
            color: palette.text.primary,
            textDecoration: 'none',
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& button': {
            ...typography.body2,
            '&.Mui-selected': {
              backgroundColor: palette.text.primary,
              color: palette.text.secondary,
              textTransform: 'capitalize',
              '&:hover, &:focus': {
                backgroundColor: palette.text.primary,
                color: palette.text.secondary,
                textTransform: 'capitalize',
              },
            },
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          width: '92%',
        },
        labelContainer: {
          ...typography.subtitle2,
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          '& button': {
            ...typography.body2,
            '&.Mui-selected': {
              backgroundColor: palette.text.primary,
              color: palette.text.secondary,
              textTransform: 'capitalize',
              '&:hover, &:focus': {
                backgroundColor: palette.text.primary,
                color: palette.text.secondary,
                textTransform: 'capitalize',
              },
            },
            '&.Mui-disabled': {
              color: 'transparent',
              userSelect: 'none',
            },
            '&:disabled': {
              color: 'transparent',
              userSelect: 'none',
            },
          },
        },
      },
    },
  }
}
