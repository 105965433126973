var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import Link from 'shared/components/Link';
import Typography from 'shared/components/Typography';
import CXMTheme, { ThemeProvider } from 'shared/core/CXMTheme';
import { MENU_WIDTH_DESKTOP } from './PageMainMenu';
var useStyles = makeStyles(function (_a) {
    var _b;
    var _c;
    var components = _a.components, palette = _a.palette, logo = _a.logo, breakpoints = _a.breakpoints;
    return deepmerge({
        menu: (_b = {
                backgroundColor: palette.background.default,
                padding: '3rem',
                boxSizing: 'border-box',
                position: 'absolute',
                zIndex: 2,
                width: '100%',
                top: '100px',
                // height: 0,
                overflowY: 'scroll',
                transition: '.3s'
            },
            _b[breakpoints.up('sm')] = {
                left: '50%',
                transform: "translateX(-".concat(MENU_WIDTH_DESKTOP / 2, "px)"),
                width: MENU_WIDTH_DESKTOP,
                borderRadius: 15,
                boxShadow: '0px 0px 5px rgba(0,0,0,.5)',
            },
            _b),
        logo: {
            display: 'block',
            height: logo.height,
            backgroundColor: palette.text.primary,
            mask: "url(".concat(logo.url, ") no-repeat center"),
            maskSize: 'contain',
        },
        link: {
            color: palette.text.primary,
            textDecoration: 'none',
            cursor: 'pointer',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            padding: '1.6rem 0',
            fontSize: '2rem',
            lineHeight: '1.2',
            '&:not(:last-child)': {
                borderBottom: "2px solid currentColor",
            },
            '&:after': {
                content: '""',
                top: 15,
                left: 110,
                width: 10,
                height: 10,
                backgroundColor: palette.storyAdsBadge.main,
                borderRadius: '50%',
                marginLeft: 5,
                display: 'none', // By default, the dot is not shown
            },
        },
        linkWithRedDot: {
            '&:after': {
                display: 'block',
            },
        },
        name: {
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        icon: {
            display: 'block',
            paddingRight: '1rem',
            width: '3rem',
            textAlign: 'center',
        },
    }, (_c = components.CxmPageExtendedMenu) === null || _c === void 0 ? void 0 : _c.styleOverrides);
});
function PageExtendedMenu(props) {
    var id = props.id, items = props.items, onClose = props.onClose, MenuItems = props.MenuItems;
    var classes = useStyles(props);
    return (_jsxs(Typography, __assign({ id: id, component: "nav", variant: "h5", className: classes.menu }, { children: [_jsx(Link, __assign({ to: '/', className: classes.logo, onClick: onClose }, { children: _jsx("span", __assign({ className: "sr-only" }, { children: "logo" })) })), _jsx(MenuItems, { items: items, classes: classes, closeMenu: onClose })] })));
}
export default function ThemedPageExtendedMenu(props) {
    // get theme from default options
    var extendedMenuTheme = useTheme().extendedMenuTheme;
    // component without theme - will inherit the theme
    if (!extendedMenuTheme)
        return _jsx(PageExtendedMenu, __assign({}, props));
    // themed component
    var theme = CXMTheme.get(extendedMenuTheme);
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(PageExtendedMenu, __assign({}, props)) })));
}
