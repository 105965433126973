var EventChannel = /** @class */ (function () {
    function EventChannel() {
        var _this = this;
        this._listeners = [];
        this._subscriptions = [];
        this.subscribe = function (listener) {
            var subscription = {
                unsubscribe: function () {
                    var i = _this._subscriptions.indexOf(subscription);
                    if (i >= 0) {
                        _this._subscriptions.splice(i, 1);
                        _this._listeners.splice(i, 1);
                    }
                },
            };
            _this._subscriptions.push(subscription);
            _this._listeners.push(listener);
            return subscription;
        };
        this.dispatch = function (event) {
            _this._listeners.forEach(function (listener) { return listener(event); });
        };
        this.reset = function () {
            _this._listeners = [];
            _this._subscriptions = [];
        };
    }
    return EventChannel;
}());
export default EventChannel;
