import { Palette, alpha } from '@mui/material/styles'

import colors from '../colors'

export default {
  primary: {
    main: colors.orange,
    dark: colors.orange,
    light: colors.orange,
    contrastText: colors.cnfDarkBrown,
  },
  secondary: {
    main: colors.white,
    dark: colors.white,
    light: colors.white,
    contrastText: colors.cnfLightBrown,
  },
  text: {
    primary: colors.cnfDarkBrown,
    secondary: colors.white,
    disabled: alpha(colors.cnfDarkBrown, 0.7),
  },
  background: {
    default: colors.white,
    paper: colors.white,
  },
  // content hub
  articlePreviewCard: {
    background: colors.cnfDarkBrown,
  },
  logo: {
    main: colors.orange,
  },
  storyAdsBadge: {
    main: colors.storyAdsBadge,
  },
} as Palette
