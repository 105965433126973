function normalizeNumber(num) {
    return !Number(num) ? 0 : num;
}
export default function formatNumber(num, locale) {
    num = normalizeNumber(num);
    return new Intl.NumberFormat(locale).format(num);
}
export function formatCurrency(num, locale, currency) {
    if (currency === void 0) { currency = 'EUR'; }
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(num);
}
export function formatShortNumber(num) {
    num = normalizeNumber(num);
    var sign = Math.sign(num);
    var abs = Math.abs(num);
    function shortNum(min, unit) {
        return (sign * (abs / min)).toFixed(1).replace('.0', '') + unit;
    }
    if (abs > 9999) {
        return shortNum(10000, 'm');
    }
    else if (abs > 999) {
        return shortNum(1000, 'k');
    }
    else {
        return String(sign * abs);
    }
}
