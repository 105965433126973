var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAppState } from 'shared/core/AppStateProvider';
import CXMTheme from 'shared/core/CXMTheme';
import useRadioAnalytics from 'shared/hooks/useRadioAnalytics';
var useStyles = makeStyles(function (_a) {
    var _b, _c, _d;
    var breakpoints = _a.breakpoints;
    return ({
        radioWrapper: (_b = {
                position: 'absolute',
                top: '2.5rem',
                left: 0,
                height: 0,
                width: '100%'
            },
            _b[breakpoints.up('sm')] = {
                top: '6rem',
                left: "calc(50% - 46vw)",
                width: '46vw',
            },
            _b[breakpoints.up('md')] = {
                left: "calc(50% - 36%)",
                width: '36%',
            },
            _b),
        radioVisible: (_c = {
                height: '60rem'
            },
            _c[breakpoints.up('sm')] = {
                height: '65rem',
            },
            _c[breakpoints.down(375)] = {
                height: '160vw',
            },
            _c),
        radioIframe: (_d = {
                border: 0,
                width: '100%',
                height: '100%'
            },
            _d[breakpoints.up('sm')] = {
                borderRadius: 16,
            },
            _d),
    });
});
var RadioContext = createContext({});
export var useRadio = function () { return useContext(RadioContext); };
export default function RadioProvider(props) {
    var _a = useState(''), radioUrl = _a[0], setRadioUrl = _a[1];
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var _c = useState(false), playing = _c[0], setPlaying = _c[1];
    var showRadio = function () { return setVisible(true); };
    var hideRadio = function () { return setVisible(false); };
    var updateRadioUrl = function (url) { return setRadioUrl(url); };
    var isRadioPlaying = function () { return playing; };
    useEffect(function () {
        var updatePlayState = function (event) {
            var data = (event || {}).data;
            setPlaying(data === 'PLAY');
        };
        window.addEventListener('message', updatePlayState);
        return function () {
            window.removeEventListener('message', updatePlayState);
        };
    }, []);
    return (_jsx(RadioContext.Provider, __assign({ value: { showRadio: showRadio, hideRadio: hideRadio, updateRadioUrl: updateRadioUrl, isRadioPlaying: isRadioPlaying, visible: visible, radioUrl: radioUrl } }, { children: props.children })));
}
export function Radio(props) {
    var _a;
    var classes = useStyles(props);
    var _b = useRadio(), visible = _b.visible, radioUrl = _b.radioUrl;
    var user = useAppState().user;
    // load the analytics script only if the radio is active
    var brand = radioUrl && CXMTheme.get('').brandId;
    useRadioAnalytics(brand || '', user === null || user === void 0 ? void 0 : user.id);
    return (_jsx("div", __assign({ id: "radioWrapper", className: classNames(classes.radioWrapper, (_a = {}, _a[classes.radioVisible] = visible, _a)) }, { children: _jsx("iframe", { id: "radio", title: "Radio", className: classes.radioIframe, src: radioUrl }) })));
}
